import React from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import { ErrorBoundary } from "../plugins/error-boundary";
import { Locations } from "../constants/locations";
import { Login } from "../containers/login";
import { QuestionList } from "../containers/question";
import { ResultList } from "../containers/Result";
import College from "../containers/college";
import MainContainer from "../containers/main-container";
import { Exam } from "../containers/exam";
import { Instructions } from "../containers/instructions";
import { StudentRegistration } from "../containers/student-registration";
import { EndPage } from "../containers/end-page";
import { FailPage } from "../containers/fail-page";
import EvaluationBreakdown from "../containers/evaluationBreakdown";

export const router = createBrowserRouter([
	{
		path: "/",
		element: <Navigate to={Locations.QUESTION} />,
		errorElement: <ErrorBoundary />,
	},
	{
		path: Locations.BASE,
		element: <MainContainer />,
		errorElement: <ErrorBoundary />,
		children: [
			{
				path: Locations.QUESTION,
				element: <QuestionList />,
				errorElement: <ErrorBoundary />,
			},
			{
				path: Locations.RESULT,
				element: <ResultList />,
				errorElement: <ErrorBoundary />,
			},
			{
				path: Locations.COLLEGE,
				element: <College />,
				errorElement: <ErrorBoundary />,
			},
			{
				path: Locations.EVALUATION_BREAKDOWN,
				element: <EvaluationBreakdown />,
				errorElement: <ErrorBoundary />,
			}
			
		],
	},
	{
		path: Locations.LOGIN,
		element: <Login />,
		errorElement: <ErrorBoundary />,
	},
	{
		path: "*",
		element: <Navigate to={Locations.BASE} />,
		errorElement: <ErrorBoundary />,
	},
	{
		path: Locations.FAIL_PAGE,
		element: <FailPage />,
		errorElement: <ErrorBoundary />,
	},
	{
		path: Locations.INSTRUCTIONS,
		element: <Instructions />,
		errorElement: <ErrorBoundary />,
	},
	{
		path: Locations.EXAM,
		element: <Exam />,
		errorElement: <ErrorBoundary />,
	},
	{
		path: Locations.REGISTRATION,
		element: <StudentRegistration />,
		errorElement: <ErrorBoundary />,
	},
	{
		path: Locations.END_PAGE,
		element: <EndPage />,
		errorElement: <ErrorBoundary />,
	},
]);
