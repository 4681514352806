import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Locations } from "../../constants/locations";
import { useAppSelector } from "../../modal/hooks";
import { APIStatus, SessionStorageKeys } from "../../constants";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store";
import { clearLogout } from "../../services/user/user.slice";
import { logout } from "../../services/user/user.service";
import { logoutModal } from "../../plugins/logout-modal";
import { showInfo } from "../../utils/util.fns";
import "./sidebar.scss";
import { Results, Questions, College } from "../../plugins/icons";

export const SideBar: React.FunctionComponent = () => {
	const dispatch = useDispatch<AppDispatch>();
	const logoutCompleted = useAppSelector((store) => store.user.logoutCompleted);
	const navigate = useNavigate();

	useEffect(() => {
		if (logoutCompleted === APIStatus.FULFILLED) {
			sessionStorage.removeItem(SessionStorageKeys.TOKEN);
			navigate(Locations.LOGIN);
			dispatch(clearLogout());
		}
		if (logoutCompleted === APIStatus.REJECTED) {
			dispatch(clearLogout());
		}
	}, [logoutCompleted]);

	const logoutClicked = (): void => {
		dispatch(logout());
	};

	return (
		<div className="custom_sidebar_container">
			<div className="custom_sidebar">
				<div className="sidebar_route_items">
					<div
						className={`sidebar_menu_item ${
							location.pathname.endsWith(Locations.QUESTION) ? "sidebar_menu_item_active" : ""
						}`}
						onClick={() => navigate(Locations.QUESTION)}
					>
						<div className="sidebar_item_content">
							<div className="sidebar_item_icon">
								<Questions />
							</div>
							<span className="sidebar_item_label">Questions</span>
						</div>
					</div>
					<div
						className={`sidebar_menu_item ${
							location.pathname.includes(Locations.RESULT) ? "sidebar_menu_item_active" : ""
						}`}
						onClick={() => navigate(Locations.RESULT)}
					>
						<div className="sidebar_item_content">
							<div className="sidebar_item_icon">
								<Results />
							</div>
							<span className="sidebar_item_label">Results</span>
						</div>
					</div>
					<div
						className={`sidebar_menu_item ${
							location.pathname.endsWith(Locations.COLLEGE) ? "sidebar_menu_item_active" : ""
						}`}
						onClick={() => navigate(Locations.COLLEGE)}
					>
						<div className="sidebar_item_content">
							<div className="sidebar_item_icon">
								<College />
							</div>
							<span className="sidebar_item_label">College</span>
						</div>
					</div>
				</div>
				<div className="sidebar_user_toggles mt-2">
					<button
						className="w-100 primary_border_btn min_height_36 font_16"
						onClick={() => logoutModal({ logout: logoutClicked })}
					>
						Logout
					</button>
				</div>
			</div>
		</div>
	);
};
