import React, { useState, useEffect } from "react";
import { ColumnConfig, PaginationWithNumber } from "../../modal/common";
import { useAppDispatch, useAppSelector } from "../../modal/hooks";
import { clearResetPage } from "../../services/table-plugin/table-plugin.slice";
import I18 from "../i18";
import "./table.scss";

export type TableHeaders = {
	id: string;
	headerText: string | React.ReactElement;
	rowElement?(row: TableData, i: number): React.ReactElement | null;
	sortable: boolean;
	maxWidth?: number;
	minWidth?: number;
	className?: string;
	headCenter?: boolean;
	contentCenter?: boolean;
	hidden?: boolean;
	fieldName?: string;
	colClicked?(e: any): void;
	resizable?: boolean;
	defaultWidth?: number;
	disabled?: boolean;
};

export type TableData = {
	[key: string]: any;
};

type TableProps = {
	headers: TableHeaders[];
	loading: boolean;
	totalResults: number;
	defaultSort: string;
	tableData: TableData[];
	columnSettings?: ColumnConfig[];
	fetchData(pagination: PaginationWithNumber): void;
	columnSettingsSaved?(columns: ColumnConfig[]): void;
	getResizedWidth?(width: number): void;
	rowClicked?(index: number): void;
};

export const Table: React.FunctionComponent<TableProps> = (props) => {
	const [pagination, setPagination] = useState<PaginationWithNumber>({
		search: "",
		pageNumber: 0,
		numResults: 50,
		sort: props.defaultSort,
		desc: false,
	});
	const [scrollBlock, setScrollBlock] = useState<boolean>(false);
	const tablePlugin = useAppSelector((store) => store.tablePlugin);

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (tablePlugin.resetPage) {
			if (pagination.pageNumber === 0) {
				props.fetchData(pagination);
			}
			setPagination((prevState) => ({ ...prevState, pageNumber: 0 }));
			dispatch(clearResetPage());
		}
	}, [tablePlugin.resetPage]);

	useEffect(() => {
		setScrollBlock(props.loading);
	}, [props.loading]);

	useEffect(() => {
		props.fetchData(pagination);
	}, [pagination.pageNumber, pagination.sort, pagination.desc, pagination.search]);

	const sortChanged = (columnId: string, sortable = true) => {
		if (sortable) {
			setPagination((prevState) => ({
				...prevState,
				pageNumber: 0,
				sort: columnId,
				desc: columnId === prevState.sort ? !prevState.desc : false,
			}));
		}
	};

	const tableScrolled = () => {
		if (!scrollBlock && props.tableData.length > 0 && props.totalResults > props.tableData.length) {
			const containerElement = document.getElementById("table_container");
			const rowElement = document.getElementById(`row-${props.tableData.length - 1}`);
			const containerRect = containerElement?.getBoundingClientRect() ?? { bottom: 0 };
			const rowRect = rowElement?.getBoundingClientRect() ?? { top: 0 };
			if (containerRect.bottom && rowRect.top && containerRect.bottom > rowRect.top) {
				setScrollBlock(true);
				setPagination((prevState) => ({ ...prevState, pageNumber: ++prevState.pageNumber }));
			}
		}
	};

	return (
		<div className="position-relative h-100">
			<div className="custom_table_container" id="table_container" onScroll={tableScrolled}>
				<table
					className={`custom_table ${
						props.columnSettings && props.columnSettings.length && props.columnSettingsSaved ? "" : ""
					}`}
				>
					<thead>
						<tr>
							{props.headers.map((header) =>
								!header.hidden ? (
									<>
										<th
											id={`custom_table_th_${header.fieldName}`}
											className={`${header.sortable ? "sortable" : ""} ${header.headCenter ? "text-center" : ""} ${
												header.className ? header.className : ""
											}`}
											key={header.id}
											style={{
												maxWidth: header.maxWidth ?? "200px",
												minWidth: header.minWidth ?? "200px",
												width: header.defaultWidth ?? "200px",
											}}
											onClick={() => sortChanged(header.id, header.sortable)}
										>
											<div className="custom_table_th">
												{header.headerText}
												{pagination.sort === header.id ? (
													<span className="sort_icon_container">
														{!pagination.desc ? (
															<svg
																width="10"
																height="6"
																viewBox="0 0 10 6"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path d="M5 6L0.669873 0.75L9.33013 0.750001L5 6Z" fill="white" />
															</svg>
														) : (
															<svg
																width="10"
																height="6"
																viewBox="0 0 10 6"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
															>
																<path d="M5 -8.74228e-07L9.33013 5.25L0.669874 5.25L5 -8.74228e-07Z" fill="white" />
															</svg>
														)}
													</span>
												) : (
													""
												)}
											</div>
										</th>
									</>
								) : null
							)}
						</tr>
					</thead>
					<tbody>
						{props.tableData.map((data, index) => (
							<tr
								className={props.rowClicked ? "cursor-pointer" : ""}
								key={`tr-${index}`}
								id={`row-${index}`}
								onClick={() => (props.rowClicked ? props.rowClicked(index) : {})}
							>
								{props.headers.map((header) =>
									!header.hidden ? (
										<>
											<td
												onClick={header.colClicked}
												className={`${header.contentCenter ? "text-center" : ""} ${
													header.className ? header.className : ""
												}`}
												key={`${header.id}-${index}`}
											>
												{header.rowElement ? header.rowElement(data, index) : data[header.id] ? data[header.id] : null}
											</td>
										</>
									) : null
								)}
							</tr>
						))}
					</tbody>
				</table>
				{props.loading && props.totalResults > props.tableData.length ? (
					<div className="custom_table_no_data">
						<I18 tkey="LOADING" />
						...
					</div>
				) : null}
				{!props.loading && props.tableData.length === 0 ? (
					<div className="custom_table_no_data">
						<I18 tkey="NO_DATA" />
					</div>
				) : null}
			</div>
		</div>
	);
};
