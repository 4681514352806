import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../modal/hooks";
import { APIStatus } from "../../constants";
import { getStudentResultsById } from "../../services/results/results.service";
import { clearGetReview, clearGetReviewData } from "../../services/results/results.slice";
import "./evaluationBreakdown.scss";
import { Breadcrumb } from "antd";
import { Locations } from "../../constants/locations";

const EvaluationBreakdown: React.FunctionComponent = () => {
	const getReviewDataCompleted = useAppSelector((store) => store.results.getReviewDataCompleted);
	const [id, setId] = useState<number | null>(null);
	const [isExpanded, setIsExpanded] = useState(false);
	const reviewData = useAppSelector((store) => store.results.reviewData);
	const studentDetails = useAppSelector((store) => store.results.studentDetails);
	const dispatch = useAppDispatch();
	const [loading, setLoading] = useState<boolean>(true);
	const navigate = useNavigate();


	useEffect(() => {
		setLoading(true);
		dispatch(getStudentResultsById(id));
		setLoading(true);
		() => {
			dispatch(clearGetReviewData());
		};
	}, []);

	useEffect(() => {
		if (getReviewDataCompleted === APIStatus.FULFILLED) {
			setLoading(false);
			dispatch(clearGetReview());
		}
		if (getReviewDataCompleted === APIStatus.REJECTED) {
			setLoading(false);
			dispatch(clearGetReview());
		}
	}, [getReviewDataCompleted]);

	const toggleView = () => {
		setIsExpanded(!isExpanded);
	};



	return (
		<div className="overview_content_box questions_listing_main_container d-flex flex-column pt-0">
			<div className="button_container pr-2">
				<Breadcrumb className="breadcrumb"
					items={[
						{
							title: <a onClick={() => navigate(Locations.RESULT)}>Result</a>,
						},
						{
							title: "Evaluation Breakdown",
						}
					]}
				/>
			</div>
			<div className="questions_listing_content_container flex_1_1_10_limit">
				{studentDetails &&<div key={"userDetails"} className="user_details">
					<ul>
						<li><label>Name</label>:<span>{studentDetails.name}</span></li>
						<li><label>College</label>:<span>{studentDetails.collegeName}</span></li>
						{isExpanded && (<>
							<li><label>Score</label>:<span>{studentDetails.score}</span></li>
							{/* <li><label>Test Date</label>:<span>{studentDetails.name}</span></li> */}
							<li><label>Exam Status</label>:<span>{studentDetails.selected ? "Selected" : "Not Selected"}</span></li>
							<li><label>Answer Status</label>:<span>{studentDetails.completed ? "Completed" : "Not Completed"}</span></li>
						</>)}
					</ul>
					<span className="view-more" onClick={toggleView}>
						{isExpanded ? "View Less" : "View More"}
					</span>
				</div>}
				{loading ? (
					<div className="d-flex align-items-center justify-content-center h-100">Loading...</div>
				) : (
					<>
						{reviewData.length === 0 ? (
							<div className="d-flex align-items-center justify-content-center h-100">No Data</div>
						) : (
							<>
								{reviewData.map((question, index) => (
									<div key={question.id} className="questions_listing_box">
										<div className="d-flex justify-content-between">
											<div className="question_container w-100">
												<div className="d-flex mb-2">
													<div className="mr-1">{index + 1}.</div>
													<div className="word_break">{question.question}</div>
												</div>
												{question.attachment ? (
													<div className="attachment_container mt-2 mb-3">
														<img src={question.attachment} />
													</div>
												) : (
													""
												)}
											</div>
										</div>
										<div className="answer_content_container">
											{question.options.map((option, index) => (
												<div
													key={index}
													className={
														`answer_box mr-3 
														${option.isAttempted && option.isCorrect? "answer_box correct_answer" : option.isAttempted ? "wrong_answer" : ""}`
													}
												>
													<div className="d-flex">
														<div className="mr-1">{index + 1}.</div>
														<div className="word_break">{option.text}</div>
														
													</div>
													{option.attachment ? (
														<div className="attachment_container py-2">
															<img src={option.attachment} />
														</div>
													) : (
														""
													)}
													{option.isCorrect&&<div className="selection-mark" >
														<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22"><defs>
															<linearGradient gradientUnits="userSpaceOnUse" y2="-2.623" x2="0" y1="986.67" id="0">
																<stop stopColor="#2d9d15"/><stop offset="1" stopColor="#2d9d15"/>
															</linearGradient><linearGradient y2="-2.623" x2="0" y1="986.67" gradientUnits="userSpaceOnUse">
																<stop stopColor="#2d9d15"/><stop offset="1" stopColor="#2d9d15"/>
															</linearGradient>
														</defs>
														<g transform="matrix(1.99997 0 0 1.99997-10.994-2071.68)" fill="#fff">
															<rect y="1037.36" x="7" height="8" width="8" fill="#2d9d15" rx="4"/>
															<path d="m123.86 12.966l-11.08-11.08c-1.52-1.521-3.368-2.281-5.54-2.281-2.173 0-4.02.76-5.541 2.281l-53.45 53.53-23.953-24.04c-1.521-1.521-3.368-2.281-5.54-2.281-2.173 0-4.02.76-5.541 2.281l-11.08 11.08c-1.521 1.521-2.281 3.368-2.281 5.541 0 2.172.76 4.02 2.281 5.54l29.493 29.493 11.08 11.08c1.52 1.521 3.367 2.281 5.54 2.281 2.172 0 4.02-.761 5.54-2.281l11.08-11.08 58.986-58.986c1.52-1.521 2.281-3.368 2.281-5.541.0001-2.172-.761-4.02-2.281-5.54" fill="#fff" transform="matrix(.0436 0 0 .0436 8.177 1039.72)" stroke="none" strokeWidth="9.512"/></g>
														</svg>	
													</div>}
												</div>
											))}
										</div>
									</div>
								))}
							</>
						)}
					</>
				)}
			</div>
		</div>
	);
};
export default EvaluationBreakdown;