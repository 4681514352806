import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../modal/hooks";
import QuestionCreateForm, { QuestionFormHandles } from "../../components/question-form";
import { getAllQuestions, getOneQuestion, questionUpdate } from "../../services/questions/questions.service";
import { APIStatus } from "../../constants";
import { clearGetOneQuestion, clearUpdateQuestion } from "../../services/questions/questions.slice";
import { Modal } from "antd";

type QuestionEditProps = {
	setIsQuestionModalClose(e: boolean): void;
	questionId: number | null;
};

export const QuestionEdit: React.FunctionComponent<QuestionEditProps> = (props) => {
	const questionFormRef = useRef<QuestionFormHandles>(null);
	const dispatch = useAppDispatch();
	const [disableForm, setDisableForm] = useState(false);
	const getOneQuestionComplete = useAppSelector((store) => store.questions.getOneQuestionCompleted);
	const updateQuestionCompleted = useAppSelector((store) => store.questions.updateQuestionDetailsCompleted);
	const questionDetails = useAppSelector((store) => store.questions.questionDetails);
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		if (props.questionId) {
			setDisableForm(true);
			dispatch(getOneQuestion(props.questionId));
		}
	}, []);

	useEffect(() => {
		if (getOneQuestionComplete === APIStatus.FULFILLED) {
			setDisableForm(false);
			questionFormRef.current?.set({
				...questionDetails,
				question: questionDetails && questionDetails.question ? questionDetails.question : "",
				options: questionDetails && questionDetails.options ? questionDetails.options : [],
				attachment: questionDetails && questionDetails.attachment ? questionDetails.attachment : "",
			});
			setLoading(false);
			dispatch(clearGetOneQuestion());
		}
		if (getOneQuestionComplete === APIStatus.REJECTED) {
			props.setIsQuestionModalClose(false);
			dispatch(clearGetOneQuestion());
		}
	}, [getOneQuestionComplete]);

	useEffect(() => {
		if (updateQuestionCompleted === APIStatus.FULFILLED) {
			setDisableForm(false);
			props.setIsQuestionModalClose(false);
			dispatch(getAllQuestions());
			dispatch(clearUpdateQuestion());
		}
		if (updateQuestionCompleted === APIStatus.REJECTED) {
			setDisableForm(false);
			dispatch(clearUpdateQuestion());
		}
	}, [updateQuestionCompleted]);

	const handleOk = () => {
		if (questionFormRef.current && questionFormRef.current.isValid()) {
			setDisableForm(true);
			dispatch(questionUpdate({ questionId: props.questionId, question: questionFormRef.current?.get() }));
		}
	};

	const handleCancel = () => {
		props.setIsQuestionModalClose(false);
	};

	return (
		<div>
			<Modal
				className="question_create_update_modal"
				title="Edit Question"
				open={true}
				closable={false}
				footer={[
					<div key={"sprint_footer"} className="d-flex align-items-center justify-content-end">
						<button className="secondary_border_btn min_width_120" disabled={loading} onClick={handleCancel}>
							Cancel
						</button>
						<button className="primary_btn min_width_120 ml-2 mr-1" disabled={loading} onClick={handleOk}>
							Save
						</button>
					</div>,
				]}
			>
				<QuestionCreateForm
					loading={loading}
					disabled={disableForm}
					ref={questionFormRef}
					questionId={props.questionId}
				/>
			</Modal>
		</div>
	);
};
