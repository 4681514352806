import React from "react";

type SearchIconSvgProps = {
	className?: string;
};

export const SearchIconSvg: React.FunctionComponent<SearchIconSvgProps> = (props) => {
	return (
		<svg
			className={props.className ?? ""}
			width="18"
			height="19"
			viewBox="0 0 18 19"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M8.02397 0C8.40587 0.0616143 8.79376 0.0969255 9.16824 0.189167C11.4977 0.762072 13.2447 2.12335 14.2819 4.32886C15.5368 6.99809 15.2775 9.58805 13.7033 12.0559C13.6439 12.1488 13.5722 12.2339 13.5061 12.3221C13.4401 12.4104 13.374 12.4991 13.2963 12.6028C13.3401 12.6511 13.3825 12.7001 13.427 12.747C14.8352 14.2405 16.2437 15.7336 17.6518 17.2271C18.0323 17.6307 18.104 18.0998 17.8518 18.5257C17.5466 19.041 16.8492 19.1585 16.3984 18.7689C16.3292 18.7091 16.2649 18.6432 16.202 18.5762C14.7931 17.0834 13.385 15.5899 11.9775 14.096C11.9358 14.052 11.9061 13.9965 11.8906 13.9746C11.4091 14.2329 10.9798 14.5042 10.5206 14.7028C8.46416 15.5899 6.40137 15.5888 4.37533 14.6184C2.25637 13.6034 0.872229 11.9182 0.267771 9.61436C-0.267797 7.57352 0.00140027 5.61303 1.063 3.79198C2.18677 1.86392 3.84329 0.664066 5.98592 0.168268C6.33248 0.0882779 6.68964 0.0551287 7.04151 0.000360395C7.369 7.69802e-08 7.69648 0 8.02397 0ZM7.53115 2.00373C4.47142 2.00805 1.9794 4.55261 1.9794 7.6726C1.9794 10.794 4.46152 13.3285 7.53044 13.3408C10.5852 13.353 13.1045 10.7811 13.0907 7.66432C13.0762 4.53244 10.5884 1.9994 7.53115 2.00373Z"
				fill="#787C7E"
			/>
		</svg>
	);
};
