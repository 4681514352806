import React, { useEffect, useState } from "react";
import { columns } from "./columns";
import { Table } from "../../plugins/table";
import { PaginationWithNumber } from "../../modal/common";
import { resetPage } from "../../services/table-plugin/table-plugin.slice";
import { deleteModal } from "../../plugins/delete-modal";
import { SearchIconSvg } from "../../components/search-icon";
import { createCollege, UpdateCollage, getAllCollege } from "../../services/college/college.service";
import { clearCreateCollege, clearUpdateCollage, cleargetAllCollege, enableLoading } from "../../services/college/college.slice";
import { useAppDispatch, useAppSelector } from "../../modal/hooks";
import { APIStatus } from "../../constants";
import CreateModal from "./createModal";
import { Colleges, Filter } from "../../modal/college";

const College: React.FunctionComponent = () => {

	const dispatch = useAppDispatch();
	const [filter, setFilter] = useState<Filter>({search: ""});
	const [visible, setVisible] = useState(false);
	const [editCollage, setEditCollage] = useState<Colleges | null>();
	const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);

	const createCollegeComplete = useAppSelector((store) => store.college.createCollegeComplete);
	const updateCollageComplete = useAppSelector((store) => store.college.updateCollageComplete);
	const collages = useAppSelector((store) => store.college.colleges);
	const totalCount = useAppSelector((store) => store.college.totalCount);
	const dataLoading = useAppSelector((store) => store.college.dataLoading);

	useEffect(() => {
		if(createCollegeComplete === APIStatus.FULFILLED || updateCollageComplete === APIStatus.FULFILLED) {
			handleCancel();
			dispatch(resetPage());
			dispatch(clearCreateCollege());
			dispatch(clearUpdateCollage());
			dispatch(cleargetAllCollege());

		}
	}, [createCollegeComplete, updateCollageComplete]);
	
	useEffect(() => {
		if(!isFirstLoad)
			dispatch(resetPage());
	}, [filter]);


	const onCreate = (values: { name: string; code: string, id: number }) => {

		if(editCollage) 
			dispatch(UpdateCollage({...values, id: editCollage?.id }));
		else 
			dispatch(createCollege(values));
	};

	const showModal = (row: Colleges ) => {
		setEditCollage(row);
		setVisible(true);
	};

	const handleCancel = () => {
		setVisible(false);
		setEditCollage(null);
	};

	const deleteClicked = (id: number) => {
		deleteModal({
			id: id,
			content: "Are you sure you want to delete the college ?",
			delete: () => {
				// setLoading(true);
				// dispatch(deleteQuestion(id));
			},
		});
	};

	const searchChanged = (search: string) => setFilter({...filter, search});

	const fetchData = (pagination: PaginationWithNumber) => {
		setIsFirstLoad(false);
		dispatch(enableLoading());
		if (pagination.pageNumber === 0) {
			dispatch(cleargetAllCollege());
		}
		let query = `offset=${pagination.pageNumber}&numResults=${pagination.numResults}`;
		query += `${filter.search ? `&search=${filter.search}` : ""}`;
		query += `${pagination.sort ? `&sortBy=${pagination.sort}` : ""}`;
		query += `${pagination.sort ? `&desc=${pagination.desc}` : ""}`;
		dispatch(getAllCollege(query));
	};
	return (
		<div className="overview_content_box result_listing_main_container d-flex flex-column">
			<div className="overview_tool_box custom_main_form filter_container d-flex align-items-center justify-content-end">
				<div className="custom_input_field_container max_width_210 flex_1_1_10 position-relative">
					<input
						type="text"
						className="custom_input_field form_input padding_right_30"
						placeholder="Search"
						onChange={(e) => searchChanged(e.target.value)}
						value={filter.search}
					/>
					<SearchIconSvg className="search_icon_svg cursor_pointer" />
				</div>

				<button className="primary_border_btn min_height_36 font_16 min_width_175 ml-2" onClick={() => setVisible(true)}>
					Add New College
				</button>
			</div>
			<div className="custom_table_main_container">
				<Table
					loading={dataLoading}
					totalResults={totalCount}
					defaultSort=""
					headers={columns(showModal, deleteClicked)}
					tableData={collages} 
					// loading={false} 
					fetchData={ e => fetchData(e)}
				/>
			</div>

			{visible &&<CreateModal
				visible={visible}
				onCreate={onCreate}
				onCancel={handleCancel}
				editData={editCollage}
			/>}
		</div>
	);
};

export default College;