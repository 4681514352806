import React from "react";
import { DatePicker, Space } from "antd";
import type { DatePickerProps } from "antd";
import dayjs from "dayjs";
import "./date-picker.scss";

type YearPickerProps = {
  value: string | null;
  handleChange(e: string): void;
};

const YearPicker: React.FC<YearPickerProps> = (props) => {
	const onChange: DatePickerProps["onChange"] = (date, dateString) => {
		props.handleChange(dateString); // Call the handleChange function with the selected year
	};

	// Convert the number value to a Dayjs object
	const yearAsDayjs = dayjs(`${props.value}`);

	return (
		<Space direction="vertical" className="date-picker-wrap">
			<DatePicker onChange={onChange} picker="year" value={yearAsDayjs} />
		</Space>
	);
};

export default YearPicker;
