import { createAsyncThunk } from "@reduxjs/toolkit";
import { Config } from "../../config";
import { axiosConfig } from "../../utils/util.fns";
import axios from "axios";
import { createCollegeModel, Colleges } from "../../modal/college";


export const createCollege = createAsyncThunk("college/create", async (payload: createCollegeModel) => {
	const response = await axios.post(Config.apiSecureUrl + "/college", payload, axiosConfig());
	return response.data;
});

export const UpdateCollage = createAsyncThunk("college/update", async (payload: Colleges) => {
	const response = await axios.put(Config.apiSecureUrl + `/college/${payload.id}`, payload, axiosConfig());
	return response.data;
});

export const getAllCollege = createAsyncThunk("college/all", async (query: string | undefined) => {
	const response = await axios.get(Config.apiUrl + "/student/colleges?"+ (query ? query : "offset=0&numResults=1000") , axiosConfig());
	return response.data;
});
