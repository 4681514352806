import React from "react";
import { Select } from "antd";
import { DropDownIcon } from "../drop-down-icon";
import { examStatus, answerStatus } from "../../constants";
type ResultFilterProps = {
	examvalue: string | null;
	answerValue: boolean | null;
	clearable?: boolean;
	handleChange(e: any, type: string): void;
};

const ResultFilter: React.FunctionComponent<ResultFilterProps> = (props) => {
	return (
		<>
			<div className="custom_input_field_container max_width_210 flex_1_1_10 ml-2">
				<Select
					className="data_selector form_input"
					placeholder={"Exam Status"}
					optionFilterProp={"children"}
					allowClear={props.clearable}
					value={props.examvalue}
					onChange={(value) => props.handleChange(value || null ,"examStatus")}
					showSearch
					suffixIcon={<DropDownIcon />}
					options={examStatus}
				/>
			</div>
		
			<div className="custom_input_field_container max_width_210 flex_1_1_10 ml-2">
				<Select
					className="data_selector form_input"
					placeholder={"Answer Status"}
					optionFilterProp={"children"}
					allowClear={props.clearable}
					value={props.answerValue}
					onChange={(value) => props.handleChange(value != undefined ? value : null, "enswerStatus")}
					showSearch
					suffixIcon={<DropDownIcon />}
					options={answerStatus}
				/>
			</div>
		</>
	);
};

export default ResultFilter;