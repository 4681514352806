import { createAsyncThunk } from "@reduxjs/toolkit";
import { Config } from "../../config";
import { axiosConfig } from "../../utils/util.fns";
import axios from "axios";
import { StudentsRegistrationPayload, tokenPayload } from "../../modal/registration";

export const tokenValidate = createAsyncThunk("get/token", async (payload: tokenPayload) => {
	const response = await axios.post(Config.apiUrl + "/exam/token", payload, axiosConfig());
	return response.data;
});
export const StudentRegister = createAsyncThunk("student/register", async (payload: StudentsRegistrationPayload) => {
	const response = await axios.post(Config.apiUrl + "/student", payload, axiosConfig());
	return response.data;
});
