import { createSlice } from "@reduxjs/toolkit";
import { APIStatus } from "../../constants";
import { login, getCurrentUserDetails, logout } from "./user.service";

type LoginResponse = {
	id: number;
	firstName: string;
	lastName: string;
	email: string;
	token: string;
	role: string;
};

export interface UserReducer {
	loginComplete: APIStatus.FULFILLED | APIStatus.REJECTED | null;
	user: LoginResponse;
	getCurrentUserComplete: APIStatus.FULFILLED | APIStatus.REJECTED | null;
	logoutCompleted: APIStatus.FULFILLED | APIStatus.REJECTED | null;
}

const initialState: UserReducer = {
	loginComplete: null,
	user: {} as LoginResponse,
	getCurrentUserComplete: null,
	logoutCompleted: null
};

const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		clearLogin(state) {
			state.loginComplete = null;
		},
		clearGetCurrentUser(state) {
			state.getCurrentUserComplete = null;
		},
		clearCurrentUser(state) {
			state.user = {} as LoginResponse;
		},
		clearLogout(state) {
			state.logoutCompleted = null;
		},
	},
	extraReducers(builder) {
		builder.addCase(login.fulfilled, (state, action) => {
			state.loginComplete = APIStatus.FULFILLED;
			state.user = action.payload.data;
		});
		builder.addCase(login.rejected, (state) => {
			state.loginComplete = APIStatus.REJECTED;
		});
		builder.addCase(getCurrentUserDetails.fulfilled, (state, action) => {
			state.getCurrentUserComplete = APIStatus.FULFILLED;
			state.user = action.payload.data;
		});
		builder.addCase(getCurrentUserDetails.rejected, (state) => {
			state.getCurrentUserComplete = APIStatus.REJECTED;
		});
		builder.addCase(logout.fulfilled, (state) => {
			state.logoutCompleted = APIStatus.FULFILLED;
		});
		builder.addCase(logout.rejected, (state) => {
			state.logoutCompleted = APIStatus.REJECTED;
		});
	},
});

export const {
	clearLogin,
	clearCurrentUser,
	clearGetCurrentUser,
	clearLogout,
} = userSlice.actions;

export default userSlice.reducer;
