import React, { useEffect } from "react";

export const FailPage: React.FunctionComponent = () => {
	useEffect(() => {
		localStorage.removeItem("isReloaded");
		localStorage.removeItem("isBackNavigate");
	}, []);
	return (
		<div className="student_module_overview_bg_container main_outer_bg">
			<div className="student_module_overview_content_box overview_inner_bg">
				<div className="overview_content_box_content d-flex flex-column align-items-center justify-content-center h-100">
					<div className="heading_text mb-2">Exam Failed !</div>
					<div className="sub_text mb-3">
						Fair assessment might have been disrupted by opening extra tabs, reloading, or navigating back during exams.
					</div>
					<div className="logo_container">
						<img className="logo_img" src="/images/logo.png" />
					</div>
					<div className="sub_text font_15">www.simplogics.com</div>
				</div>
			</div>
		</div>
	);
};
