import React from "react";
import { TableHeaders } from "../../plugins/table";
import { Result } from "../../modal/result";
import { StatusDropDown } from "../../components/status-dropDown";
import { useAppDispatch } from "../../modal/hooks";
import { actionChange } from "../../services/results/results.service";

export const columns = (onClicked: (id: number) => void): TableHeaders[] => {
	const dispatch = useAppDispatch();
	return [
		{
			id: "selected",
			headerText: "Action",
			sortable: false,
			resizable: true,
			rowElement: (row: Result) => (
				<div className="custom_secondary_form">
					{
						!row.completed ? <span className="incomplete">Not Completed</span>: 
							<StatusDropDown
								value={row.selected}
								handleChange={(e: string) => {
									dispatch(
										actionChange({
											id: row.id,
											payload: {
												selected: e,
											},
										})
									);
								}}
								disabled={!row.completed}
							/>
					}
				</div>
			),
			maxWidth: 220,
			minWidth: 220,
			defaultWidth: 220,
		},
		{
			id: "year",
			headerText: "Year",
			sortable: true,
			resizable: true,
			rowElement: (row: Result) => <div className="active" onClick={ () =>onClicked(row.id)}>{row.year}</div>,
			maxWidth: 200,
			minWidth: 150,
			defaultWidth: 180,
		},
		{
			id: "score",
			headerText: "Score",
			sortable: true,
			resizable: true,
			rowElement: (row: Result) => <div className="active" onClick={ () =>onClicked(row.id)}>{row.score}</div>,
			maxWidth: 180,
			minWidth: 180,
			defaultWidth: 180,
		},
		{
			id: "name",
			headerText: "Name",
			sortable: false,
			resizable: true,
			rowElement: (row: Result) => <div className="active" onClick={ () =>onClicked(row.id)}>{row.name}</div>,
			maxWidth: 220,
			minWidth: 220,
			defaultWidth: 220,
			
		},
		{
			id: "collegeName",
			headerText: "College",
			sortable: true,
			resizable: true,
			rowElement: (row: Result) => <div className="active" onClick={ () =>onClicked(row.id)}>{row.collegeName}</div>,
			maxWidth: 280,
			minWidth: 280,
			defaultWidth: 280,
			
		},
		// {
		// 	id: "collegeId",
		// 	headerText: "College ID",
		// 	sortable: false,
		// 	resizable: true,
		// 	rowElement: (row: Result) => <div className="active" onClick={ () =>onClicked(row.id)}>{row.collegeId}</div>,
		// 	maxWidth: 200,
		// 	minWidth: 200,
		// 	defaultWidth: 200,
			
		// },
		{
			id: "department",
			headerText: "Department",
			sortable: false,
			resizable: true,
			rowElement: (row: Result) => <div className="active" onClick={ () =>onClicked(row.id)}>{row.department}</div>,
			maxWidth: 250,
			minWidth: 250,
			defaultWidth: 250,
			
		},
		{
			id: "cgpa",
			headerText: "CGPA",
			sortable: true,
			resizable: true,
			rowElement: (row: Result) => <div className="active" onClick={ () =>onClicked(row.id)}>{row.cgpa}</div>,
			maxWidth: 160,
			minWidth: 160,
			defaultWidth: 160,
			
		},
		{
			id: "backpapers",
			headerText: "No of Backlogs",
			sortable: true,
			resizable: true,
			rowElement: (row: Result) => <div className="active" onClick={ () =>onClicked(row.id)}>{row.backPapers}</div>,
			maxWidth: 200,
			minWidth: 200,
			defaultWidth: 200,
			
		},
		{
			id: "email",
			headerText: "Email ID",
			sortable: false,
			resizable: true,
			rowElement: (row: Result) => <div className="active" onClick={ () =>onClicked(row.id)}>{row.email}</div>,
			maxWidth: 250,
			minWidth: 250,
			defaultWidth: 250,
			
		},
		{
			id: "contactNo",
			headerText: "Contact Number",
			sortable: false,
			resizable: true,
			rowElement: (row: Result) => <div className="active" onClick={ () =>onClicked(row.id)}>{row.contactNo}</div>,
			maxWidth: 180,
			minWidth: 180,
			defaultWidth: 180,
			
		},
		{
			id: "place",
			headerText: "Place",
			sortable: false,
			resizable: true,
			rowElement: (row: Result) => <div className="active" onClick={ () =>onClicked(row.id)}>{row.place}</div>,
			maxWidth: 200,
			minWidth: 200,
			defaultWidth: 200,
			
		},
		{
			id: "gender",
			headerText: "Gender",
			sortable: false,
			resizable: true,
			rowElement: (row: Result) => <div className="active" onClick={ () =>onClicked(row.id)}>{row.gender}</div>,
			maxWidth: 160,
			minWidth: 160,
			defaultWidth: 160,
			
		},
	];
};
