import React, { useEffect, useRef, useState } from "react";
import { Modal } from "antd";
import QuestionForm, { QuestionFormHandles } from "../../components/question-form";
import { useAppDispatch, useAppSelector } from "../../modal/hooks";
import { getAllQuestions, questionCreate } from "../../services/questions/questions.service";
import { APIStatus } from "../../constants";
import { clearCreateQuestion } from "../../services/questions/questions.slice";

type QuestionCreateProps = {
	setIsCreateQuestionModalOpen(e: boolean): void;
	loading: boolean;
};

export const QuestionCreate: React.FunctionComponent<QuestionCreateProps> = (props) => {
	const createQuestionComplete = useAppSelector((store) => store.questions.createQuestionComplete);
	const questionFormRef = useRef<QuestionFormHandles>(null);
	const [disableForm, setDisableForm] = useState(false);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (createQuestionComplete === APIStatus.FULFILLED) {
			props.setIsCreateQuestionModalOpen(false);
			setDisableForm(false);
			dispatch(getAllQuestions());
			dispatch(clearCreateQuestion());
		}
		if (createQuestionComplete === APIStatus.REJECTED) {
			setDisableForm(false);
			dispatch(clearCreateQuestion());
		}
	}, [createQuestionComplete]);

	const handleOk = () => {
		if (questionFormRef.current && questionFormRef.current.isValid()) {
			setDisableForm(true);
			dispatch(questionCreate(questionFormRef.current.get()));
		}
	};

	const handleCancel = () => {
		props.setIsCreateQuestionModalOpen(false);
	};

	return (
		<div>
			<Modal
				className="question_create_update_modal"
				title="Add New Question"
				open={true}
				closable={false}
				footer={[
					<div key={"sprint_footer"} className="d-flex align-items-center justify-content-end">
						<button className="secondary_border_btn min_width_120" onClick={handleCancel}>
							Cancel
						</button>
						<button className="primary_btn min_width_120 ml-2 mr-1" onClick={handleOk}>
							Add
						</button>
					</div>,
				]}
			>
				<div className="custom_create_update_form_outer_box w-100 h-100">
					<QuestionForm disabled={disableForm} ref={questionFormRef} />
				</div>
			</Modal>
		</div>
	);
};
