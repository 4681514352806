import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../modal/hooks";
import { APIStatus } from "../../constants";
import { deleteQuestion, getAllQuestions } from "../../services/questions/questions.service";
import { clearDeleteQuestion, clearGetAllQuestions } from "../../services/questions/questions.slice";
import { QuestionCreate } from "../question-create";
import "./questions.scss";
import { deleteModal } from "../../plugins/delete-modal";
import { QuestionEdit } from "../question-edit";
import { GenerateLink } from "../generate-link";
import { Edit, Delete} from "../../plugins/icons";
export const QuestionList: React.FunctionComponent = () => {
	const getAllQuestionsComplete = useAppSelector((store) => store.questions.getAllQuestionsComplete);
	const deleteQuestionComplete = useAppSelector((store) => store.questions.deleteQuestionComplete);
	const [id, setId] = useState<number | null>();
	const [isCreateQuestionModalOpen, setIsCreateQuestionModalOpen] = useState(false);
	const [isEditQuestionModalOpen, setIsEditQuestionModalOpen] = useState(false);
	const questions = useAppSelector((store) => store.questions.question);
	const dispatch = useAppDispatch();
	const [loading, setLoading] = useState<boolean>(true);

	const deleteClicked = (id: number) => {
		deleteModal({
			id: id,
			content: "Are you sure you want to delete the question ?",
			delete: () => {
				setLoading(true);
				dispatch(deleteQuestion(id));
			},
		});
	};

	useEffect(() => {
		setLoading(true);
		dispatch(getAllQuestions());
		setLoading(true);
	}, []);

	useEffect(() => {
		if (getAllQuestionsComplete === APIStatus.FULFILLED) {
			setLoading(false);
			dispatch(clearGetAllQuestions());
		}
		if (getAllQuestionsComplete === APIStatus.REJECTED) {
			setLoading(false);
			dispatch(clearGetAllQuestions());
		}
	}, [getAllQuestionsComplete]);

	useEffect(() => {
		if (deleteQuestionComplete === APIStatus.FULFILLED) {
			setLoading(false);
			dispatch(getAllQuestions());
			dispatch(clearDeleteQuestion());
		}
		if (deleteQuestionComplete === APIStatus.REJECTED) {
			setLoading(false);
			dispatch(clearDeleteQuestion());
		}
	}, [deleteQuestionComplete]);

	const onAddQuestionClick = () => {
		setIsCreateQuestionModalOpen(true);
	};

	const questionEditClicked = (id: number | null) => {
		setIsEditQuestionModalOpen(true);
		setId(id);
	};

	return (
		<div className="overview_content_box questions_listing_main_container d-flex flex-column pt-0">
			<div className="button_container pr-2">
				<GenerateLink />
				<button className="primary_border_btn min_height_36 font_16 min_width_175 ml-2" onClick={onAddQuestionClick}>
					Add New Question
				</button>
			</div>
			<div className="questions_listing_content_container flex_1_1_10_limit">
				{loading ? (
					<div className="d-flex align-items-center justify-content-center h-100">Loading...</div>
				) : (
					<>
						{questions.length === 0 ? (
							<div className="d-flex align-items-center justify-content-center h-100">No Data</div>
						) : (
							<>
								{questions.map((question, index) => (
									<div key={question.id} className="questions_listing_box">
										<div className="d-flex justify-content-between">
											<div className="question_container w-100">
												<div className="d-flex mb-2">
													<div className="mr-1">{index + 1}.</div>
													<div className="word_break">{question.question}</div>
												</div>
												{question.attachment ? (
													<div className="attachment_container mt-2 mb-3">
														<img src={question.attachment} />
													</div>
												) : (
													""
												)}
											</div>
											<div className="qn_edit_delete_container min_width_65 text-right">
												<span className="pr-1" onClick={() => questionEditClicked(question.id)}>
													<Edit />
												</span>
												<span
													className={loading ? "" : ""}
													onClick={() => {
														!loading ? deleteClicked(question.id) : "";
													}}
												>
													<Delete />
												</span>
											</div>
										</div>
										<div className="answer_content_container">
											{question.options.map((option, index) => (
												<div
													key={index}
													className={option.isCorrect ? "answer_box correct_answer mr-3" : "answer_box mr-3"}
												>
													<div className="d-flex">
														<div className="mr-1">{index + 1}.</div>
														<div className="word_break">{option.text}</div>
													</div>
													{option.attachment ? (
														<div className="attachment_container py-2">
															<img src={option.attachment} />
														</div>
													) : (
														""
													)}
												</div>
											))}
										</div>
									</div>
								))}
							</>
						)}
					</>
				)}

				{isCreateQuestionModalOpen ? (
					<QuestionCreate loading={loading} setIsCreateQuestionModalOpen={setIsCreateQuestionModalOpen} />
				) : (
					""
				)}
				{isEditQuestionModalOpen ? (
					<QuestionEdit setIsQuestionModalClose={setIsEditQuestionModalOpen} questionId={id ? id : null} />
				) : (
					""
				)}
			</div>
		</div>
	);
};
