import React from "react";
import { gender } from "../../constants";
import { Select } from "antd";
import { Options } from "../../modal/registration";
import { DropDownIcon } from "../drop-down-icon";

type GenderDropDownProps = {
	value: string | null;
	clearable?: boolean;
	handleChange(e: string): void;
};

export const GenderDropDown: React.FunctionComponent<GenderDropDownProps> = (props) => {
	const Option = Select;

	return (
		<Select
			className="data_selector form_input"
			placeholder={"Gender"}
			optionFilterProp={"children"}
			allowClear={props.clearable}
			value={props.value}
			onChange={(value) => props.handleChange(value)}
			showSearch
			suffixIcon={<DropDownIcon />}
		>
			{gender.map((el: string, i: number) => {
				return (
					<Option key={`user_role_${i}`} value={el}>
						{el}
					</Option>
				);
			})}
		</Select>
	);
};
