import { createSlice } from "@reduxjs/toolkit";
import { APIStatus } from "../../constants";
import { actionChange, getAllResults, getStudentResultsById } from "./results.service";
import { ResultReducer } from "../../modal/result";
import _ from "lodash";

const initialState: ResultReducer = {
	getAllResultsComplete: null,
	result: [],
	resultLoading: false,
	totalCount: 0,
	selectedCount:0,
	submittedCount:0,
	columns: [],
	actionChangeCompleted: null,
	reviewData:  [
		{
			"id": 3,
			"question": "What is your name",
			"attachment": "",
			"attachmentName": "",
			"options": [
				{
					"text": "Arjun",
					"attachment": "",
					"attachmentName": "",
					"isCorrect": false,
					"isAttempted" : false
				},
				{
					"text": "John",
					"attachment": "",
					"attachmentName": "",
					"isCorrect": true,
					"isAttempted" : true
				}
			]
		},
		{
			"id": 6,
			"question": "Test my question",
			"attachment": "",
			"attachmentName": "",
			"options": [
				{
					"text": "True",
					"attachment": "",
					"attachmentName": "",
					"isCorrect": true,
					"isAttempted" : false
				},
				{
					"text": "False",
					"attachment": "",
					"attachmentName": "",
					"isCorrect": false,
					"isAttempted" : true
				}
			]
		},
		{
			"id": 9,
			"question": "True or false please",
			"attachment": "",
			"attachmentName": "",
			"options": [
				{
					"text": "Flase",
					"attachment": "",
					"attachmentName": "",
					"isCorrect": true,
					"isAttempted" : true
				},
				{
					"text": "True",
					"attachment": "",
					"attachmentName": "",
					"isCorrect": false,
					"isAttempted" : false
				}
			]
		},
		{
			"id": 85,
			"question": "What is the reason behind physics",
			"attachment": "",
			"attachmentName": "",
			"options": [
				{
					"text": "Maths",
					"attachment": "",
					"attachmentName": "",
					"isCorrect": false,
					"isAttempted" : true
				},
				{
					"text": "Chemistry",
					"attachment": "",
					"attachmentName": "",
					"isCorrect": true,
					"isAttempted" : false
				}
			]
		},
		{
			"id": 88,
			"question": "True of Ralse",
			"attachment": "",
			"attachmentName": "",
			"options": [
				{
					"text": "True",
					"attachment": "",
					"attachmentName": "",
					"isCorrect": false,
					"isAttempted" : true
				},
				{
					"text": "False",
					"attachment": "",
					"attachmentName": "",
					"isCorrect": true,
					"isAttempted" : false
				}
			]
		}
	],
	studentDetails: {
		id: 1223,
		selected: true,
		score: 1455,
		name : "Vipin",
		collegeName: "CMS",
		completed: false
	},
	getReviewDataCompleted: null

};

const resultsSlice = createSlice({
	name: "results",
	initialState,
	reducers: {
		clearGetAllResults(state) {
			state.getAllResultsComplete = null;
		},
		clearAllResults(state) {
			state.result = [];
		},
		clearActionChange(state) {
			state.actionChangeCompleted = null;
		},
		clearGetReview(state) {
			state.getReviewDataCompleted = null;
		},
		clearGetReviewData(state) {
			state.reviewData = [];
			state.studentDetails = null;
		},
	},
	extraReducers(builder) {
		builder.addCase(getAllResults.pending, (state) => {
			state.resultLoading = true;
		});
		builder.addCase(getAllResults.fulfilled, (state, action) => {
			state.getAllResultsComplete = APIStatus.FULFILLED;
			state.resultLoading = false;
			state.result = _.unionBy(state.result, action.payload.data.data, "id");
			state.submittedCount =  action.payload.data.submittedCount;
			state.totalCount = action.payload.data.totalCount;
			state.selectedCount = action.payload.data.selectedCount;
		});
		builder.addCase(getAllResults.rejected, (state) => {
			state.getAllResultsComplete = APIStatus.REJECTED;
			state.resultLoading = false;
		});
		builder.addCase(actionChange.fulfilled, (state) => {
			state.actionChangeCompleted = APIStatus.FULFILLED;
		});
		builder.addCase(actionChange.rejected, (state) => {
			state.actionChangeCompleted = APIStatus.REJECTED;
		});

		builder.addCase(getStudentResultsById.fulfilled, (state, action) => {
			state.getReviewDataCompleted = APIStatus.FULFILLED;
			// state.studentDetails = action.payload.data.student;
			// state.reviewData = action.payload.data.data;
			state.reviewData = [
				{
					"id": 3,
					"question": "What is your name",
					"attachment": "",
					"attachmentName": "",
					"options": [
						{
							"text": "Arjun",
							"attachment": "",
							"attachmentName": "",
							"isCorrect": false,
							"isAttempted" : false
						},
						{
							"text": "John",
							"attachment": "",
							"attachmentName": "",
							"isCorrect": true,
							"isAttempted" : true
						}
					]
				},
				{
					"id": 6,
					"question": "Test my question",
					"attachment": "",
					"attachmentName": "",
					"options": [
						{
							"text": "True",
							"attachment": "",
							"attachmentName": "",
							"isCorrect": true,
							"isAttempted" : false
						},
						{
							"text": "False",
							"attachment": "",
							"attachmentName": "",
							"isCorrect": false,
							"isAttempted" : true
						}
					]
				},
				{
					"id": 9,
					"question": "True or false please",
					"attachment": "",
					"attachmentName": "",
					"options": [
						{
							"text": "Flase",
							"attachment": "",
							"attachmentName": "",
							"isCorrect": true,
							"isAttempted" : true
						},
						{
							"text": "True",
							"attachment": "",
							"attachmentName": "",
							"isCorrect": false,
							"isAttempted" : false
						}
					]
				},
				{
					"id": 85,
					"question": "What is the reason behind physics",
					"attachment": "",
					"attachmentName": "",
					"options": [
						{
							"text": "Maths",
							"attachment": "",
							"attachmentName": "",
							"isCorrect": false,
							"isAttempted" : true
						},
						{
							"text": "Chemistry",
							"attachment": "",
							"attachmentName": "",
							"isCorrect": true,
							"isAttempted" : false
						}
					]
				},
				{
					"id": 88,
					"question": "True of Ralse",
					"attachment": "",
					"attachmentName": "",
					"options": [
						{
							"text": "True",
							"attachment": "",
							"attachmentName": "",
							"isCorrect": false,
							"isAttempted" : true
						},
						{
							"text": "False",
							"attachment": "",
							"attachmentName": "",
							"isCorrect": true,
							"isAttempted" : false
						}
					]
				}
			],
			state.studentDetails = {
				id: 1223,
				selected: true,
				score: 1455,
				name : "Vipin",
				collegeName: "CMS",
				completed: false
			};

		});
		builder.addCase(getStudentResultsById.rejected, (state) => {
			state.getReviewDataCompleted = APIStatus.REJECTED;
		});

	},
});

export const { 
	clearGetAllResults, 
	clearAllResults, 
	clearActionChange,
	clearGetReview,
	clearGetReviewData
} = resultsSlice.actions;

export default resultsSlice.reducer;
