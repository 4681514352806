import React, { useEffect, useState } from "react";
import { Modal, message, Button } from "antd";
import { useAppDispatch, useAppSelector } from "../../modal/hooks";
import { getLink } from "../../services/questions/questions.service";
import "./generate-link.scss";
import { APIStatus } from "../../constants";
import { clearGetLink } from "../../services/questions/questions.slice";
import { Config } from "../../config";
import { axiosMultiPartConfig } from "../../utils/util.fns";
import axios from "axios";
import { Spinner, Share } from "../../plugins/icons";
export const GenerateLink: React.FunctionComponent = () => {
	const getLinkComplete = useAppSelector((store) => store.questions.getLinkComplete);
	const token = useAppSelector((store) => store.questions.token);
	const linkId = useAppSelector((store) => store.questions.linkId);
	const dispatch = useAppDispatch();
	const [isGenerateLinkModalOpen, setIsGenerateLinkModalOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const link = `${Config.siteUrl}/${token}/registration`;
	const [file, setFile] = useState<File | null>(null);
	const [uploading, setUploading] = useState(false);
	const [progress, setProgress] = useState(0);
	const [error, setError] = useState<string | null>(null);


	const handleCopyLink = () => {
		if (window.navigator.clipboard) {
			window.navigator.clipboard.writeText(link);
			message.success("Link copied to clipboard");
		}
	};

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const selectedFile = event.target.files?.[0];
		
		if (selectedFile) {
			// Check if the file is an Excel file
			const isExcelFile = selectedFile.type === "application/vnd.ms-excel" ||
								selectedFile.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
								selectedFile.name.endsWith(".xls") ||
								selectedFile.name.endsWith(".xlsx");
	
			if (isExcelFile) {
				setFile(selectedFile);
				setError(null); // Clear any previous errors
			} else {
				setError("Please upload a valid Excel file (.xls or .xlsx).");
			}
		}
	};

	const handleShareLink = async () => {
		if (!file) return;

		const formData = new FormData();
		formData.append("file", file);
		setUploading(true);

		try {

			const response = await axios.post(`${Config.apiSecureUrl}/exam/upload/${linkId}`, formData, {
				...axiosMultiPartConfig(),
				onUploadProgress: (progressEvent) => {
					if (progressEvent.total) {
						const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
						setProgress(percentCompleted);
					}
				},
			});

			console.log("Upload successful:", response.data);
			handleClear();
			// message.success("Link share successful");
		} catch (error) {
			console.error("Upload failed:", error);
			// message.error("Link share failed");
		} finally {
			setUploading(false);
		}
	};

	const handleCancel = () => {
		setIsGenerateLinkModalOpen(false);
	};
	const onGenerateLinkClick = () => {
		setLoading(true);
		dispatch(getLink());
	};
	const handleClear = () => {
		const fileInput = document.getElementById("fileInput") as HTMLInputElement;
		if (fileInput) {
			fileInput.value = "";
		}
		setFile(null);
		setError(null);
		setUploading(false);
		setProgress(0);

	};

	useEffect(() => {
		if (getLinkComplete === APIStatus.FULFILLED) {
			setLoading(false);
			setIsGenerateLinkModalOpen(true);
			dispatch(clearGetLink());
		}
		if (getLinkComplete === APIStatus.REJECTED) {
			setLoading(false);
			dispatch(clearGetLink());
		}
	}, [getLinkComplete]);

	return (
		<>
			<button
				className="primary_border_btn min_height_36 font_16 min_width_175"
				disabled={loading}
				onClick={onGenerateLinkClick}
			>
				Generate Link
			</button>
			{isGenerateLinkModalOpen ? (
				<Modal
					title="Examination Link"
					visible={true}
					className="generate_link_pop_up_screen"
					onCancel={handleCancel}
					closable={true}
					footer={null}
				>
					<div className="generate_link_box_content mb-2">
						<div className="generate_link_sub_header mb-2">You may share the link to initiate the exam.</div>
						<div className="d-flex align-items-center w-100">
							<div className="generate_link text-break">{link}</div>
							<div className="button_container">
								<button 
									className="primary_border_btn font_16 min_width_120 ml-2" 
									onClick={handleCopyLink}>
									Copy
								</button>
							</div>
						</div>
						<div className="upload_csv">
							<div className="upload_button">
								
								<div className="file_overlap" >
									<input 
										type="file" 
										name="mailFile" 
										className="uploadInput"   
										id="fileInput"  
										onChange={handleFileChange}  />	
								</div>
								<div className="file_name">
									{uploading && <Spinner />}
									{error && <p style={{ color: "red" }}>{error}</p>}
								</div>
								{(file || error) &&  !uploading && 
								<button onClick={handleClear} className="close_button" > X </button>}
							</div>
							<button 
								disabled={ error || !file || uploading ? true : false} 
								className="primary_border_btn font_16 min_width_120 ml-2 send_button" 
								onClick={handleShareLink}>
								<Share />
								Share
							</button>
						</div>
					</div>
				</Modal>
			) : (
				""
			)}
		</>
	);
};
