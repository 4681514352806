import React, { useEffect, useState} from "react";
import { Select } from "antd";
import { DropDownIcon } from "../drop-down-icon";
import { useAppDispatch, useAppSelector } from "../../modal/hooks";
import { getAllCollege } from "../../services/college/college.service";

type CollegeDropDownProps = {
	value: number | null;
	clearable?: boolean;
	handleChange(e: number | null): void;
};
interface Option {
    value: number;
    label: string;
}

const CollegeDropDown: React.FunctionComponent<CollegeDropDownProps> = (props) => {

	const dispatch = useAppDispatch();
	const colleges = useAppSelector((store) => store.college.colleges);
	const [options, setOptions] = useState<Option[]>([]);

	useEffect( () => {
		dispatch(getAllCollege());
	}, []);

	useEffect(() => {
		const newOptions = colleges.map((e): Option => ({ value: e.id, label: e.name }));
		setOptions(newOptions);
	}, [colleges]);

	return (
		<Select
			className="data_selector form_input"
			placeholder={"College"}
			optionFilterProp={"children"}
			allowClear={props.clearable}
			value={props.value}
			onChange={(value) => props.handleChange(value)}
			showSearch
			suffixIcon={<DropDownIcon />}
			options={options}
		>
		</Select>
	);
};
export default CollegeDropDown;
