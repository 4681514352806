import React, { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../modal/hooks";
import {
	StudentRegister as StudentRegister,
	tokenValidate,
} from "../../services/students-registration/studentRegistration.service";
import { APIStatus } from "../../constants";
import {
	clearRegisterStudent,
	clearValidateToken,
} from "../../services/students-registration/studentRegistration.slice";
import { useNavigate, useParams } from "react-router-dom";
import { Locations } from "../../constants/locations";
import StudentRegistrationForm, {
	RegistrationFormHandles as RegistrationFormHandles,
} from "../../components/registration";
import { createLocation } from "../../utils/util.fns";
import "./student-registration.scss";
import { Modal } from "antd";

export const StudentRegistration: React.FunctionComponent = () => {
	const registrationFormRef = useRef<RegistrationFormHandles>(null);
	const { token } = useParams();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [loading, setLoading] = useState(true);
	const validateTokenCompleted = useAppSelector((store) => store.studentsRegistration.validateTokenCompleted);
	const registerStudentCompleted = useAppSelector((store) => store.studentsRegistration.registerStudentCompleted);

	useEffect(() => {
		localStorage.removeItem("isReloaded");
		localStorage.removeItem("isBackNavigate");
		setLoading(true);
		dispatch(tokenValidate({ token: token ? token : "" }));
	}, []);

	useEffect(() => {
		if (!token) {
			navigate(Locations.FAIL_PAGE);
		}
	}, [token]);

	useEffect(() => {
		if (validateTokenCompleted === APIStatus.FULFILLED) {
			setLoading(false);
			dispatch(clearValidateToken());
		}
		if (validateTokenCompleted === APIStatus.REJECTED) {
			navigate(Locations.FAIL_PAGE);
			dispatch(clearValidateToken());
		}
	}, [validateTokenCompleted]);

	const onClickResterClick = () => {
		if (registrationFormRef.current && registrationFormRef.current.isValid()) {
			setLoading(true);
			dispatch(StudentRegister(registrationFormRef.current.get()));
		}
	};

	useEffect(() => {
		if (registerStudentCompleted === APIStatus.FULFILLED) {
			setLoading(false);
			navigate(createLocation(Locations.INSTRUCTIONS, { token: token ?? "" }));
			dispatch(clearRegisterStudent());
		}
		if (registerStudentCompleted === APIStatus.REJECTED) {
			setLoading(false);
			dispatch(clearRegisterStudent());
		}
	}, [registerStudentCompleted]);

	return (
		<div className="w-100 h-100 position-relative">
			{loading ? <div className="initial_loading">Loading...</div> : ""}
			<div className="registration_main_container">
				<div className="registration_bg_container">
					<div className="application_title_container">
						<div className="application_main_title">Talent Quest</div>
						<div className="application_sub_title">by Simplogics Solutions</div>
					</div>
				</div>
				<div className="registration_card_container">
					<div className="registration_card_content_container h-100 d-flex flex-column">
						<div className="text-right p-4">
							<img className="logo_img" src="/images/logo.png" />
						</div>
						<div className="registration_card flex_1_1_10">
							<StudentRegistrationForm disabled={loading} ref={registrationFormRef} />
							<div className="text-right py-4 mr-3">
								<button
									className="primary_btn font_16 min_height_36 min_width_130"
									onClick={() => onClickResterClick()}
								>
									Register
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
