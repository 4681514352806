import React, { useEffect, useState } from "react";
import { Header } from "../../components/header";
import { SideBar } from "../../components/side-bar";
import { Outlet } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../modal/hooks";
import { getCurrentUserDetails } from "../../services/user/user.service";
import { APIStatus } from "../../constants";
import { clearCurrentUser, clearGetCurrentUser } from "../../services/user/user.slice";
import "./main-container.scss";

export const MainContainer: React.FunctionComponent = () => {
	const dispatch = useAppDispatch();
	const [loading, setLoading] = useState<boolean>(true);
	const user = useAppSelector((store) => store.user);

	useEffect(() => {
		setLoading(true);
		dispatch(getCurrentUserDetails());
	}, []);

	useEffect(() => {
		if (user.getCurrentUserComplete === APIStatus.FULFILLED) {
			setLoading(false);
			dispatch(clearGetCurrentUser());
		}
		if (user.getCurrentUserComplete === APIStatus.REJECTED) {
			setLoading(false);
			dispatch(clearCurrentUser());
			dispatch(clearGetCurrentUser());
		}
	}, [user.getCurrentUserComplete]);

	return (
		<div className="main_container">
			{loading ? (
				<div className="h-100 d-flex align-items-center justify-content-center">Loading...</div>
			) : (
				<>
					<Header />
					<div className="main_content_container">
						<SideBar />
						<div className="overview_container position-relative">
							<Outlet />
						</div>
					</div>
				</>
			)}
		</div>
	);
};
export default MainContainer;
