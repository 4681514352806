import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import { APIStatus } from "../../constants";
import { useAppDispatch, useAppSelector } from "../../modal/hooks";
import { answers, examPayload, failedExam, getAllQuestions, submitExam } from "../../services/exam/exam.service";
import { Questions } from "../../modal/question";
import { clearGetAllQuestions, clearSubmitExam } from "../../services/exam/exam.slice";
import { useNavigate, useParams } from "react-router-dom";
import { Timer } from "../../components/timer";
import { Locations } from "../../constants/locations";
import "./exam.scss";
// import FullScreenComponent from "./FullScreenComponent";
export const Exam: React.FunctionComponent = () => {
	const getAllQuestionsCompleted = useAppSelector((store) => store.exam.getAllQuestionsCompleted);
	const submitExamCompleted = useAppSelector((store) => store.exam.submitExamCompleted);
	const questionDetails = useAppSelector((store) => store.exam.questionDetails);
	const studentsDetails = useAppSelector((store) => store.studentsRegistration.studentsDetails);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { token } = useParams();
	const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
	const [questions, setQuestions] = useState<Questions[]>([]);
	const [timeOutModalOpen, setTimeOutModalOpen] = useState<boolean>(false);
	const [submitModalOpen, setSubmitModalOpen] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	
	useEffect(() => {
		// Define the function to handle page reload or tab close
		const handleBeforeUnload = (event: BeforeUnloadEvent) => {
			alert("You attempted to reload or leave the page. The exam has been failed.");
			// Set the returnValue to display a confirmation dialog before leaving
			event.preventDefault();
			event.returnValue = ""; // This is required for some browsers to trigger the dialog.
		};
		window.addEventListener("beforeunload", handleBeforeUnload);
		// Cleanup function to remove the event listeners
		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, []);
	
	useEffect(() => {
		dispatch(getAllQuestions());
		setLoading(true);
		const isExamActive = localStorage.getItem("examActive");
		if (isExamActive) {
			navigate(Locations.FAIL_PAGE);
		} else {
			localStorage.setItem("examActive", "true");
		}
	}, []);

	const handleNext = () => {
		if (currentQuestionIndex < questions.length - 1) {
			setCurrentQuestionIndex(currentQuestionIndex + 1);
		}
	};

	const handlePrev = () => {
		if (currentQuestionIndex > 0) {
			setCurrentQuestionIndex(currentQuestionIndex - 1);
		}
	};

	useEffect(() => {
		const handleSideKey = (event: KeyboardEvent) => {
			switch (event.key) {
			case "ArrowLeft":
				handlePrev();
				break;
			case "ArrowRight":
				handleNext();
				break;
			default:
				break;
			}
		};
		window.addEventListener("keydown", handleSideKey);
		return () => {
			window.removeEventListener("keydown", handleSideKey);
		};
	}, [currentQuestionIndex, handleNext, handlePrev]);

	useEffect(() => {
		const handlePopstate = () => {
			if (localStorage.getItem("isBackNavigate") === "true") {
				const studentId = localStorage.getItem("studentsDetails");
				const inNumber = parseInt(studentId ? studentId : "");
				dispatch(failedExam({ id: inNumber, token: { token: token ? token : "" } }));
				navigate(Locations.FAIL_PAGE);
			}
		};
		window.addEventListener("popstate", handlePopstate);
		localStorage.setItem("isBackNavigate", "true");
	}, []);

	useEffect(() => {
		const handleVisibilityChange = () => {
			if (document.visibilityState === "visible") {
				const studentId = localStorage.getItem("studentsDetails");
				if (studentId) {
					const inNumber = parseInt(studentId ? studentId : "");
					dispatch(failedExam({ id: inNumber, token: { token: token ? token : "" } }));
					navigate(Locations.FAIL_PAGE);
				}
			}
		};
		document.addEventListener("visibilitychange", handleVisibilityChange);
		return () => {
			document.removeEventListener("visibilitychange", handleVisibilityChange);
		};
	}, []);

	useEffect(() => {
		if (studentsDetails.id) {
			localStorage.setItem("studentsDetails", studentsDetails.id.toString());
		}
	}, [studentsDetails.id]);

	useEffect(() => {
		if (localStorage.getItem("isReloaded") === "true") {
			const studentId = localStorage.getItem("studentsDetails");
			const inNumber = parseInt(studentId ? studentId : "");
			dispatch(failedExam({ id: inNumber, token: { token: token ? token : "" } }));
			navigate(Locations.FAIL_PAGE);
		}
		const handleBeforeUnload = (event: any) => {
			event.preventDefault();
			localStorage.setItem("isReloaded", "true");
		};
		window.addEventListener("beforeunload", handleBeforeUnload);
		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, []);

	useEffect(() => {
		if (getAllQuestionsCompleted === APIStatus.FULFILLED) {
			setQuestions(JSON.parse(JSON.stringify(questionDetails)));
			setLoading(false);
			dispatch(clearGetAllQuestions());
		}
		if (getAllQuestionsCompleted === APIStatus.REJECTED) {
			setLoading(false);
			dispatch(clearGetAllQuestions());
		}
	}, [getAllQuestionsCompleted]);

	useEffect(() => {
		if (submitExamCompleted === APIStatus.FULFILLED) {
			setLoading(false);
			navigate(Locations.END_PAGE);
			dispatch(clearSubmitExam());
		}
		if (submitExamCompleted === APIStatus.REJECTED) {
			setLoading(false);
			dispatch(clearSubmitExam());
		}
	}, [submitExamCompleted]);

	const handleTimerEnd = () => {
		setTimeOutModalOpen(true);
	};

	const handleOptionClick = (optionId: number) => {
		const tempQuestions = [...questions];
		const currentQuestion = tempQuestions[currentQuestionIndex];
		if (currentQuestion.selectedOption === optionId) {
			tempQuestions[currentQuestionIndex] = { ...tempQuestions[currentQuestionIndex], selectedOption: null };
		} else {
			tempQuestions[currentQuestionIndex] = { ...tempQuestions[currentQuestionIndex], selectedOption: optionId };
		}
		setQuestions([...tempQuestions]);
	};

	const handleSubmit = () => {
		setSubmitModalOpen(true);
	};

	const submitClicked = () => {
		const answersPayload: answers[] = questions.map((el) => ({
			questionId: el.id,
			selectedOptionId: el.selectedOption || null,
		}));
		const payload: examPayload = {
			studentId: studentsDetails.id,
			examToken: `${token}`,
			questions: answersPayload,
		};
		dispatch(submitExam(payload));
		setLoading(true);
		setSubmitModalOpen(false);
		setTimeOutModalOpen(false);
	};

	const cancelClicked = () => {
		setSubmitModalOpen(false);
	};

	return (
		<div className="student_module_overview_main_container main_outer_bg d-flex flex-column h-100">
			<div className="logo_container">
				<img className="logo_img mr-2" src="/images/logo.png" />
			</div>
			<div className="student_module_overview_content_box flex_1_1_10 p-4">
				<div className="student_module_overview_content_box_content">
					<div className="student_exam_container d-flex flex-column">
						<div className="w-100 d-flex">
							<div className="w-50 d-flex align-items-end justify-content-between font_16">
								<div className="word_break">
									<div className="font_medium">Hi, {studentsDetails.name}</div>
									<div>{studentsDetails.email}</div>
								</div>
								<div>
									<span>Question</span>
									<span className="pl-2 font_bold">
										{currentQuestionIndex + 1}/{questions.length}
									</span>
								</div>
							</div>
							<div className="w-50 text-right">
								<Timer handleTimerEnd={handleTimerEnd} loading={loading} />
							</div>
						</div>
						<div className="student_exam_content_box overview_outer_border_box flex_1_1_10 overflow-auto mt-3 font_15">
							{questions.length === 0 ? (
								<div className="d-flex align-items-center justify-content-center h-100">Loading....</div>
							) : (
								<div>
									<div className="exam_question_box_container">
										{questions.length > 0 && (
											<>
												{" "}
												<div className="d-flex">
													<div className="mr-1 font_bold">{currentQuestionIndex + 1}.</div>
													<div className="word_break">{questions[currentQuestionIndex].question}</div>
												</div>
												{questions[currentQuestionIndex].questionMedia ? (
													<div className="exam_img_attachment_container my-2">
														<img src={questions[currentQuestionIndex].questionMedia} />
													</div>
												) : (
													""
												)}
											</>
										)}
									</div>
									<div className="exam_answer_box_container px-3">
										{questions.length &&
											questions[currentQuestionIndex].options.length &&
											questions[currentQuestionIndex].options.map((option, index) => (
												<>
													<div
														className={
															option.id === questions[currentQuestionIndex].selectedOption
																? "exam_answer_box answer"
																: "exam_answer_box"
														}
														key={index}
														onClick={() => handleOptionClick(option.id)}
													>
														<div className="d-flex">
															<div className="mr-1 font_bold">{index + 1}.</div>
															<div className="word_break">{option.option}</div>
														</div>
														{option.optionMedia ? (
															<div className="exam_img_attachment_container py-2">
																<img src={option.optionMedia} />
															</div>
														) : (
															""
														)}
													</div>
												</>
											))}
									</div>
								</div>
							)}
						</div>
					</div>
					<div className="mt-2 d-flex align-items-center justify-content-end mb-4">
						<button
							className="secondary_border_btn font_16 min_height_36 min_width_130"
							onClick={handlePrev}
							disabled={currentQuestionIndex === 0 || loading}
						>
							Previous
						</button>
						<button
							className="primary_btn forward_btn font_16 min_height_36 min_width_130 ml-2"
							onClick={handleNext}
							disabled={currentQuestionIndex === questions.length - 1 || loading}
						>
							Next
						</button>
						<button
							className="primary_btn font_16 min_height_36 min_width_130 ml-2"
							onClick={handleSubmit}
							disabled={loading}
						>
							Submit All
						</button>
					</div>
				</div>
			</div>
			{timeOutModalOpen ? (
				<div>
					<Modal
						getContainer={false}
						title="Time Out"
						open={true}
						closable={false}
						footer={[
							<div key={"sprint_footer"} className="d-flex align-items-center justify-content-end mr-3">
								<button className="primary_btn min_width_120 ml-2 mr-1" onClick={() => submitClicked()}>
									Close
								</button>
							</div>,
						]}
					>
						<div className="px-4">
							The exam has ended as the allotted time has expired. All the attended answers will be submitted.
						</div>
					</Modal>
				</div>
			) : (
				""
			)}
			{submitModalOpen ? (
				<div>
					<Modal
						getContainer={false}
						title="Submit Answers"
						open={true}
						closable={false}
						footer={[
							<div key={"sprint_footer"} className="d-flex align-items-center justify-content-end mr-3">
								<button className="secondary_border_btn min_width_120" onClick={cancelClicked}>
									No
								</button>
								<button className="primary_btn min_width_120 ml-2 mr-1" onClick={submitClicked}>
									Yes
								</button>
							</div>,
						]}
					>
						<div className="px-4">Are you sure to submit all your answers and complete the exam ?</div>
					</Modal>
				</div>
			) : (
				""
			)}
		</div>
	);
};
