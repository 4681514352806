import { RootReducer } from "../modal/root-reducer";
import userReducer from "../services/user/user.slice";
import questionReducer from "../services/questions/questions.slice";
import resultReducer from "../services/results/results.slice";
import studentsReducer from "../services/students-registration/studentRegistration.slice";
import examReducer from "../services/exam/exam.slice";
import tablePluginReducer from "../services/table-plugin/table-plugin.slice";
import collageReducer from "../services/college/college.slice";

export const rootReducer: RootReducer = {
	user: userReducer,
	questions: questionReducer,
	results: resultReducer,
	studentsRegistration: studentsReducer,
	exam: examReducer,
	tablePlugin: tablePluginReducer,
	college: collageReducer
};
