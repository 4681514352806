import React, { useEffect, memo } from "react";
import { Modal, Form, Input } from "antd";
import { Colleges } from "../../modal/college";
import "./results.scss";
interface CreateModalProps {
    visible: boolean;
    onCreate: (values: { name: string; code: string, id: number }) => void;
    onCancel: () => void;
    editData: Colleges | null | undefined
}

const CreateModal: React.FC<CreateModalProps> = ({ visible, onCreate, onCancel, editData }) => {
	const [form] = Form.useForm();

	useEffect(() => {
		form.setFieldsValue(editData);
	}, [editData]);

	const handleOk = () => {
		form.validateFields()
			.then(values => {
				// form.resetFields();
				onCreate(values);
			})
			.catch(info => {
				console.log("Validate Failed:", info);
			});
	};

	return (
		<Modal
			className="add-collage"
			visible={visible}
			title={`${editData ? "Edit" : "Add a new"} college`}
			okText={editData ? "Update" : "Create"}
			cancelText="Cancel"
			onCancel={onCancel}
			onOk={handleOk}
		>
			<Form
				form={form}
				layout="vertical"
				name="form_in_modal"
				initialValues={{ modifier: "public" }}
				className="modal-form"
			>
				<Form.Item
					name="name"
					label="Name"
					rules={[{ required: true, message: "Please input the name" }]}
				>
					<Input maxLength={99} />
				</Form.Item>
				<Form.Item
					name="code"
					label="Code"
					rules={[{ required: true, message: "Please input the code" }]}
				>
					<Input maxLength={20} />
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default memo(CreateModal);
