export enum Locations {
	BASE = "/",
	LOGIN = "/login",
	DASHBOARD = "/dashboard",
	QUESTION = "/questions",
	RESULT = "/result",
	SIDEBAR = "/side-bar",
	QUESTION_CREATE = "/createQuestion",
	FAIL_PAGE = "/fail",
	INSTRUCTIONS = "/:token/instructions",
	EXAM = "/:token/exam",
	REGISTRATION = "/:token/registration",
	END_PAGE = "/end-page",
	COLLEGE = "/college",
	EVALUATION_BREAKDOWN = "/result/review/:id"
}
