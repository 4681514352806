import { StartExamReducer, Time } from "../../modal/exam";
import { createSlice } from "@reduxjs/toolkit";
import { startExam, getAllQuestions, submitExam, failedExam } from "./exam.service";
import { APIStatus } from "../../constants";

const initialState: StartExamReducer = {
	startExamCompleted: null,
	exam: [],
	getAllQuestionsCompleted: null,
	questionDetails: [],
	submitExamCompleted: null,
	failedExamCompleted: null,
	time: {} as Time,
};

const examSlice = createSlice({
	name: "exam",
	initialState,
	reducers: {
		clearStartExamCompleted(state) {
			state.startExamCompleted = null;
		},
		clearGetAllQuestions(state) {
			state.getAllQuestionsCompleted = null;
		},
		clearSubmitExam(state) {
			state.submitExamCompleted = null;
		},
		clearFailedExam(state) {
			state.submitExamCompleted = null;
		},
	},
	extraReducers(builder) {
		builder.addCase(startExam.fulfilled, (state, action) => {
			state.startExamCompleted = APIStatus.FULFILLED;
			state.time = action.payload.data;
		});
		builder.addCase(startExam.rejected, (state) => {
			state.startExamCompleted = APIStatus.REJECTED;
		});
		builder.addCase(getAllQuestions.fulfilled, (state, action) => {
			state.getAllQuestionsCompleted = APIStatus.FULFILLED;
			state.questionDetails = action.payload.data;
		});
		builder.addCase(getAllQuestions.rejected, (state) => {
			state.getAllQuestionsCompleted = APIStatus.REJECTED;
		});
		builder.addCase(submitExam.fulfilled, (state) => {
			state.submitExamCompleted = APIStatus.FULFILLED;
		});
		builder.addCase(submitExam.rejected, (state) => {
			state.submitExamCompleted = APIStatus.REJECTED;
		});
		builder.addCase(failedExam.fulfilled, (state) => {
			state.failedExamCompleted = APIStatus.FULFILLED;
		});
		builder.addCase(failedExam.rejected, (state) => {
			state.failedExamCompleted = APIStatus.REJECTED;
		});
	},
});

export const {
	clearStartExamCompleted,clearGetAllQuestions, clearSubmitExam, clearFailedExam
} = examSlice.actions;
export default examSlice.reducer;
