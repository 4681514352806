import { createSlice } from "@reduxjs/toolkit";
import { APIStatus } from "../../constants";
import { getAllCollege, createCollege, UpdateCollage } from "./college.service";
import { CollageReducer } from "../../modal/college";

const initialState: CollageReducer = {
	createCollegeComplete: null,
	updateCollageComplete: null,
	getAllCollegeComplete: null,
	colleges: [],
	totalCount: 0,
	dataLoading: false
};

const collegeslice = createSlice({
	name: "colleges",
	initialState,
	reducers: {
		clearCreateCollege(state) {
			state.createCollegeComplete = null;
		},
		
		clearUpdateCollage(state) {
			state.updateCollageComplete = null;
		},
		
		cleargetAllCollege(state) {
			state.getAllCollegeComplete = null;
		},
		enableLoading(state) {
			state.dataLoading = true;
		},
	},
	extraReducers(builder) {

		builder.addCase(UpdateCollage.fulfilled, (state, action) => {
			state.updateCollageComplete = APIStatus.FULFILLED;
		});
		builder.addCase(UpdateCollage.rejected, (state) => {
			state.updateCollageComplete = APIStatus.REJECTED;
		});

		builder.addCase(createCollege.fulfilled, (state, action) => {
			state.createCollegeComplete = APIStatus.FULFILLED;
		});
		builder.addCase(createCollege.rejected, (state) => {
			state.createCollegeComplete = APIStatus.REJECTED;
		});


		builder.addCase(getAllCollege.fulfilled, (state, action) => {
			state.getAllCollegeComplete = APIStatus.FULFILLED;
			state.colleges = action.payload?.data?.data || [];
			state.totalCount =  action?.payload?.data?.count || 0;
			state.dataLoading = false;
		});
		builder.addCase(getAllCollege.rejected, (state) => {
			state.getAllCollegeComplete = APIStatus.REJECTED;
			state.dataLoading = false;
		});
		
	},
});

export const {
	clearCreateCollege,
	clearUpdateCollage,
	cleargetAllCollege,
	enableLoading
} = collegeslice.actions;

export default collegeslice.reducer;
