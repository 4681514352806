import { RadioChangeEvent } from "antd";
import React, { forwardRef, useState, useImperativeHandle, useRef } from "react";
import { Invalid, QuestionState } from "../../modal/question/index";
import { mustHaveLetter, whiteSpace } from "../../constants";
import "./question-create-form.scss";
import { QuestionGenerate } from "../question-generate";
import { showError } from "../../utils/util.fns";

export type QuestionFormHandles = {
	isValid(): boolean;
	get(): QuestionState;
	set(questionData: QuestionState): void;
};

type QuestionProps = {
	disabled: boolean;
	questionId?: number | null;
	loading?: boolean;
};

const QuestionForm: React.ForwardRefRenderFunction<QuestionFormHandles, QuestionProps> = (props, ref) => {
	const questionRef = useRef<HTMLInputElement | null>(null);
	const [question, setQuestion] = useState<QuestionState>({
		question: "",
		attachment: "",
		attachmentName: "",
		options: [
			{
				text: "",
				attachment: "",
				attachmentName: "",
				isCorrect: false,
			},
		],
	});

	const [invalid, setInvalid] = useState<Invalid>({
		question: false,
		options: {},
		optionLength: false,
		isCorrect: false,
	});

	useImperativeHandle(ref, () => ({
		isValid: (): boolean => {
			const prevState: Invalid = JSON.parse(JSON.stringify(invalid));
			let valid = true;
			if (!question.question || !mustHaveLetter.test(question.question)) {
				if (!question.attachment) {
					prevState.question = true;
					valid = false;
				}
			}
			if (question.options.length < 2) {
				prevState.optionLength = true;
				valid = false;
			}
			if (!question.options.some((option) => option.isCorrect)) {
				prevState.isCorrect = true;
				valid = false;
			}
			question.options.forEach((option, i) => {
				if (!option.text || whiteSpace.test(option.text)) {
					if (!option.attachment) {
						prevState.options = { ...prevState.options, [i]: true };
						valid = false;
					}
				}
			});
			setInvalid(prevState);
			return valid;
		},
		get: (): QuestionState => {
			const payload: QuestionState = {
				question: question.question,
				attachment: question.attachment,
				attachmentName: question.attachmentName,
				options: question.options,
			};
			return payload;
		},
		set: (data: QuestionState): void => {
			setQuestion(() => JSON.parse(JSON.stringify(data)));
		},
	}));

	const handleAddButtonClick = () => {
		setInvalid((prevState) => ({ ...prevState, optionLength: false }));
		if (question.options.length < 4) {
			setQuestion((prevState) => ({
				...prevState,
				options: [
					...prevState.options,
					{
						text: "",
						attachment: "",
						attachmentName: "",
						isCorrect: false,
					},
				],
			}));
		}
	};

	const handleQuestionFileUpload = (file: FileList | null) => {
		if (file && file.length > 0 && questionRef.current) {
			if (file[0].size <= 10 * 1024 * 1024) {
				const reader = new FileReader();
				reader.onloadend = () => {
					const base64String = reader.result;
					if (base64String && typeof base64String === "string") {
						setQuestion((prevState) => ({ ...prevState, attachment: base64String, attachmentName: file[0].name }));
					}
				};
				reader.readAsDataURL(file[0]);
			} else {
				showError("File should be less than 10 mb");
				setQuestion((prevState) => ({ ...prevState, attachment: "", attachmentName: "" }));
			}
		}
		setInvalid((prevState) => ({ ...prevState, question: false }));
	};

	const handleRadioChange = (i: number, e: RadioChangeEvent, checked: boolean) => {
		setInvalid((prevState) => ({ ...prevState, isCorrect: false }));
		setQuestion((prevState) => ({
			...prevState,
			options: prevState.options.map((option, index) => ({
				...option,
				isCorrect: index === i && checked,
			})),
		}));
	};

	const setOptions = (index: number, e: React.ChangeEvent<HTMLTextAreaElement>) => {
		const data = question.options;
		data[index].text = e.target.value;
		return data;
	};

	const setOptionsAttachment = (index: number, file: string, name: string) => {
		const data = question.options;
		data[index].attachment = file;
		data[index].attachmentName = name;
		return data;
	};

	const handleOptionFileUpload = (index: number, file: FileList | null) => {
		if (file && file.length > 0) {
			if (file[0].size <= 10 * 1024 * 1024) {
				const reader = new FileReader();
				reader.onloadend = () => {
					const base64String = reader.result;
					if (base64String && typeof base64String === "string") {
						setQuestion((prevState) => ({
							...prevState,
							options: setOptionsAttachment(index, base64String, file[0].name),
						}));
					}
				};
				reader.readAsDataURL(file[0]);
			} else {
				showError("File should be less than 10 mb");
				setQuestion((prevState) => ({
					...prevState,
					options: setOptionsAttachment(index, "", ""),
				}));
			}
			setInvalid((prevState) => ({
				...prevState,
				options: { ...prevState.options, [index]: false },
			}));
		}
	};

	const deleteClicked = (index: number) => {
		if (question.options.length > 1) {
			question.options.splice(index, 1);
			setQuestion({ ...question });
			setInvalid((prevState) => ({
				...prevState,
				options: { ...prevState.options, [index]: false },
			}));
		}
	};

	const handleClickQuestion = () => {
		questionRef.current?.click();
	};

	const crossButtonClick = () => {
		const updatedQuestion = { ...question };
		updatedQuestion.attachment = "";
		updatedQuestion.attachmentName = "";
		setQuestion(updatedQuestion);
	};

	const crossButtonOptionClick = (index: number) => {
		const updatedOptions = [...question.options];
		updatedOptions[index].attachment = "";
		updatedOptions[index].attachmentName = "";
		setQuestion((prevState) => ({
			...prevState,
			options: updatedOptions,
		}));
	};

	return (
		<>
			{props.loading ? (
				<div className="h-100 d-flex align-items-center justify-content-center">Loading...</div>
			) : (
				<div className="custom_main_form d-flex flex-column w-100 h-100 px-3">
					<div>
						<div className="main_form_content flex_1_1_10">
							<div className="custom_questions_box position-relative">
								<div className="custom_input_label">Question</div>
								<div className="custom_input_field_container position-relative w-100">
									<textarea
										className="custom_input_text_area padding_right_40 height_70"
										disabled={props.disabled}
										inputMode="text"
										placeholder="Enter your question"
										value={question.question}
										onChange={(e) => {
											setQuestion((prevState) => ({ ...prevState, question: e.target.value }));
											setInvalid((prevState) => ({ ...prevState, question: false }));
										}}
									/>
									{invalid.question ? (
										<span className="invalid_message invalid_top_minus_16">Enter a valid question</span>
									) : (
										""
									)}
									<div className="custom_input_field_icon right_15" onClick={handleClickQuestion}>
										<svg
											className="attach_svg cursor_pointer"
											width="31"
											height="31"
											viewBox="0 0 31 31"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M8.25789 31C7.90143 30.9398 7.5418 30.8967 7.18979 30.8175C3.80694 30.057 1.48965 28.0652 0.432351 24.7648C-0.456569 21.9917 0.0473008 19.3858 1.74063 17.0183C2.01703 16.6323 2.32837 16.2641 2.6664 15.9302C7.35118 11.299 12.0436 6.67472 16.7347 2.0492C18.8944 -0.0800892 21.8554 -0.587064 24.5869 0.702553C28.2316 2.42246 29.442 7.20831 27.0415 10.4358C26.7778 10.7907 26.4811 11.1272 26.1665 11.4384C21.5237 16.0265 16.8758 20.6089 12.2285 25.192C10.403 26.9924 7.68858 27.0684 5.86626 25.3738C4.14243 23.7705 4.02107 21.0329 5.59749 19.2876C5.69915 19.1754 5.80336 19.0652 5.91137 18.9587C10.0249 14.8991 14.1391 10.8401 18.2533 6.78118C18.3035 6.73175 18.3562 6.68549 18.3931 6.6519C18.9052 7.17282 19.411 7.68803 20.0007 8.28816C19.9638 8.30844 19.8355 8.34646 19.7516 8.42885C15.6761 12.4403 11.6071 16.4581 7.53163 20.4708C7.00171 20.993 6.67131 21.5855 6.73548 22.3504C6.80919 23.2249 7.26731 23.8466 8.05584 24.1958C8.82403 24.5354 9.57697 24.4416 10.2683 23.9562C10.4081 23.858 10.5352 23.7395 10.6571 23.6191C15.3063 19.0373 19.9517 14.4523 24.6035 9.87373C25.5667 8.92568 26.0878 7.80654 26.049 6.44214C25.988 4.29574 24.2337 2.43134 22.0898 2.23425C20.6526 2.1018 19.4129 2.52766 18.3874 3.53781C13.6804 8.17409 8.97017 12.8091 4.27459 17.4587C2.15364 19.5588 1.63897 22.4606 2.95615 25.1077C3.96643 27.1381 5.65213 28.3479 7.9008 28.6996C10.2098 29.0602 12.1878 28.3479 13.8424 26.7161C18.9503 21.6786 24.0577 16.6412 29.1637 11.6019C29.2641 11.503 29.3403 11.3807 29.4172 11.2831C29.9719 11.8478 30.4783 12.3636 31 12.8947C30.9733 12.9238 30.9085 12.998 30.8392 13.067C25.4657 18.3675 20.101 23.6761 14.704 28.9525C14.209 29.4366 13.5279 29.749 12.8988 30.0754C11.9127 30.5868 10.8503 30.8695 9.73837 30.9544C9.68944 30.9582 9.64242 30.9842 9.59413 31C9.14808 31 8.70267 31 8.25789 31Z"
												fill="#454849"
											/>
										</svg>
									</div>
									<input
										accept=".png, .jpg, .jpeg, .svg, .webp"
										type="file"
										ref={questionRef}
										style={{ display: "none" }}
										onChange={(e) => handleQuestionFileUpload(e.target.files)}
									/>
								</div>
								{question.attachmentName ? (
									<div className="attachment_img_box">
										<div className="attachment_img_container d-flex align-items-center justify-content-between">
											{question.attachmentName}
											{question.attachmentName ? (
												<div className="clear_icon_svg cursor_pointer ml-2" onClick={() => crossButtonClick()}>
													<svg
														width="14"
														height="14"
														viewBox="0 0 14 14"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<path
															d="M0 0.546663C0.0175343 0.50575 0.0350687 0.464838 0.0522377 0.42356C0.223197 0.0107797 0.732424 -0.130954 1.08969 0.136075C1.14777 0.179545 1.19927 0.232147 1.25078 0.283287C3.12622 2.15797 5.0013 4.03264 6.87565 5.90842C6.91364 5.94641 6.94469 5.99134 6.98524 6.04065C7.04369 5.98476 7.08314 5.94897 7.12076 5.91098C9.00936 4.02351 10.8976 2.13605 12.7844 0.247124C12.9827 0.0484048 13.2081 -0.0483976 13.4883 0.0242955C13.9602 0.146303 14.1542 0.700816 13.862 1.09241C13.8185 1.15049 13.7659 1.20163 13.7144 1.25314C11.843 3.12526 9.97119 4.99701 8.09903 6.86841C8.06031 6.90676 8.01611 6.93964 7.96095 6.98676C8.0099 7.03827 8.04497 7.07662 8.08186 7.11352C9.96644 8.99842 11.8503 10.8837 13.7367 12.7668C13.9624 12.9922 14.0651 13.2435 13.9537 13.5573C13.8686 13.7965 13.6782 13.9178 13.4536 14C13.3623 14 13.2713 14 13.18 14C12.9743 13.9339 12.8238 13.7932 12.6748 13.6438C10.8238 11.7896 8.97064 9.93759 7.11784 8.08483C7.08022 8.0472 7.04113 8.01177 6.98232 7.95551C6.94031 8.00921 6.91072 8.05597 6.87236 8.09433C5.02359 9.94453 3.17335 11.7933 1.32567 13.6442C1.17663 13.7936 1.02612 13.9342 0.82046 14C0.729136 14 0.638177 14 0.546852 14C0.272513 13.9098 0.086941 13.7308 0 13.4532C0 13.3618 0 13.2709 0 13.1796C0.0668496 12.9743 0.206759 12.8234 0.356166 12.6744C2.21042 10.8234 4.06285 8.9703 5.91528 7.11753C5.9529 7.07991 5.9887 7.04082 6.04533 6.98165C5.9887 6.93635 5.93829 6.90384 5.89665 6.8622C4.04641 5.01345 2.19764 3.16325 0.346303 1.3156C0.200184 1.16985 0.0642925 1.02118 0 0.820267C0 0.728944 0 0.637986 0 0.546663Z"
															fill="#A3A2A4"
														/>
													</svg>
												</div>
											) : (
												""
											)}
										</div>
									</div>
								) : (
									""
								)}
							</div>
							<div className="custom_answer_box d-inline-block w-100 mt-3 position-relative">
								<div className="custom_option_answer mt-2">
									{question.options.map((el, i) => (
										<QuestionGenerate
											disabled={props.disabled}
											key={`options_generate_${i}`}
											questionOptions={el}
											index={i}
											optionValue={el.isCorrect ? i : null}
											handleRadioChange={handleRadioChange}
											setQuestion={setQuestion}
											setInvalid={setInvalid}
											invalid={invalid}
											setOptions={setOptions}
											handleOptionFileUpload={handleOptionFileUpload}
											deleteClicked={deleteClicked}
											question={question}
											length={question.options.length}
											fileName={question.attachmentName ? { index: i, name: question.attachmentName } : null}
											crossButtonOptionClick={() => crossButtonOptionClick(i)}
											handleAddButtonClick={handleAddButtonClick}
										/>
									))}
								</div>
								{invalid.isCorrect ? (
									<span className="invalid_message invalid_top_minus_16 invalid_left_0">
										Select an answer to continue
									</span>
								) : (
									""
								)}
							</div>
						</div>
						<div className="mt-3 mandatory_text_note pl_2">
							Note: Please select the radio button for the correct answer
						</div>
					</div>
				</div>
			)}
		</>
	);
};
export default forwardRef(QuestionForm);
