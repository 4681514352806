import React from "react";
import { TableHeaders } from "../../plugins/table";
import { Colleges } from "../../modal/college";
import { Edit, Delete} from "../../plugins/icons";

export const columns = (showModal: (row: Colleges) => void, deleteClicked:(id: number) => void): TableHeaders[] => {

	return [
		{
			id: "name",
			headerText: "Name",
			sortable: true,
			resizable: true,
			rowElement: (row: Colleges) => <div>{row.name}</div>,
			maxWidth: 100,
			minWidth: 100,
			defaultWidth: 100,
		},
		{
			id: "code",
			headerText: "Code",
			sortable: false,
			resizable: true,
			rowElement: (row: Colleges) => <div>{row.code}</div>,
			maxWidth: 220,
			minWidth: 220,
			defaultWidth: 220,
		},
		{
			id: "selected",
			headerText: "Action",
			sortable: false,
			resizable: true,
			rowElement: (row: Colleges) => (
				<div className="custom_secondary_form">
					<span title="Edit" className="pr-1" onClick={() =>showModal(row)}><Edit/></span>
					{/* <span title="Delete" onClick={() => deleteClicked(row.id)}><Delete/></span> */}
				</div>
			),
			maxWidth: 100,
			minWidth: 100,
			defaultWidth: 100,
		}
	];
};
