import React from "react";
import { SessionStorageKeys } from "../../constants";
import { Locations } from "../../constants/locations";

type AuthRouterProps = {
	children: React.ReactElement;
};

export const AuthRouter = (props: AuthRouterProps) => {
	const userToken = sessionStorage.getItem(SessionStorageKeys.TOKEN);
	if (userToken && [Locations.LOGIN].includes(window.location.pathname as Locations)) {
		window.location.href = Locations.BASE;
		return null;
	} else if (userToken && !["null", "undefined"].includes(userToken ?? "")) {
		return props.children;
	} else if (!userToken && [Locations.LOGIN].includes(window.location.pathname as Locations)) {
		return props.children;
	} else if (
		!userToken &&
		([Locations.FAIL_PAGE, Locations.END_PAGE].includes(window.location.pathname as Locations) ||
			location.pathname.includes("/registration") ||
			location.pathname.includes("/instructions") ||
			location.pathname.includes("/exam"))
	) {
		return props.children;
	} else {
		sessionStorage.removeItem(SessionStorageKeys.TOKEN);
		window.location.href = Locations.LOGIN;
		return null;
	}
};
