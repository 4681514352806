import { createSlice } from "@reduxjs/toolkit";
import { APIStatus } from "../../constants";
import { studentsDetails, studentsRegistrationReducer } from "../../modal/registration";
import { tokenValidate, StudentRegister as StudentRegister } from "./studentRegistration.service";

const initialState: studentsRegistrationReducer = {
	validateTokenCompleted: null,
	registerStudentCompleted: null,
	studentsDetails: {} as studentsDetails
};

const studentsRegistrationSlice = createSlice({
	name: "studentsRegistration",
	initialState,
	reducers: {
		clearValidateToken(state) {
			state.validateTokenCompleted = null;
		},
		clearRegisterStudent(state) {
			state.registerStudentCompleted = null;
		}
	},
	extraReducers(builder) {
		builder.addCase(tokenValidate.fulfilled, (state) => {
			state.validateTokenCompleted = APIStatus.FULFILLED;
		});
		builder.addCase(tokenValidate.rejected, (state) => {
			state.validateTokenCompleted = APIStatus.REJECTED;
		});
		builder.addCase(StudentRegister.fulfilled, (state, action) => {
			state.registerStudentCompleted = APIStatus.FULFILLED;
			state.studentsDetails = action.payload.data;
		});
		builder.addCase(StudentRegister.rejected, (state) => {
			state.registerStudentCompleted = APIStatus.REJECTED;
		});
	},
});

export const {
	clearValidateToken,
	clearRegisterStudent: clearRegisterStudent
} = studentsRegistrationSlice.actions;

export default studentsRegistrationSlice.reducer;