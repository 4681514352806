import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../modal/hooks";
import "./timer.scss";

type TimerProps = {
	loading: boolean;
	handleTimerEnd: () => void;
};
const cSecond = 1800;
export const Timer: React.FunctionComponent<TimerProps> = (props) => {
	const timeLeft = useAppSelector((store) => store.exam.time.remainingTime);
	const [timer, setTimer] = useState<number>(cSecond);

	useEffect(() => {
		const calculatedTimeLeftInSeconds: number = (timeLeft - 0.5) * 60;
		if (calculatedTimeLeftInSeconds - 30 < cSecond) {
			setTimer(calculatedTimeLeftInSeconds);
		}
	}, [timeLeft]);

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			if (timer > 0) {
				setTimer(timer - 1);
			} else {
				props.handleTimerEnd();
			}
		}, 1000);
		return () => clearTimeout(timeoutId);
	}, [timer]);

	const getTimeFromTimer = (): string => {
		const hour = Math.floor(timer / 3600);
		const minute = Math.floor((timer % 3600) / 60);
		const seconds = timer % 60;
		return `${String(hour).padStart(2, "0")}:${String(minute).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
	};

	return (
		<>
			{!props.loading && (
				<div className="timer_box d-inline-block text-center">
					<div className="timer_text">Time Remaining</div>
					<div className={timer <= 60 ? "timer alert_timer" : "timer"}>{`${getTimeFromTimer()} Minutes`}</div>
				</div>
			)}
		</>
	);
};
