import { Radio, RadioChangeEvent } from "antd";
import React, { useRef } from "react";
import { Invalid, Option, QuestionState } from "../../modal/question";

type QuestionProps = {
	disabled: boolean;
	questionId?: number | null;
	questionOptions: Option;
	index: number;
	key: string;
	optionValue: number | null;
	handleRadioChange(i: number, e: RadioChangeEvent, checked: boolean): void;
	setQuestion(e: any): void;
	setInvalid(e: any): void;
	invalid: Invalid;
	setOptions(index: number, e: React.ChangeEvent<HTMLTextAreaElement>): void;
	handleOptionFileUpload(index: number, file: FileList | null): void;
	deleteClicked(index: number): void;
	question: QuestionState;
	length: number;
	fileName: { index: number; name: string } | null;
	crossButtonOptionClick: (index: number) => void;
	handleAddButtonClick: () => void;
};

export const QuestionGenerate: React.FunctionComponent<QuestionProps> = (props) => {
	const optionRef = useRef<HTMLInputElement | null>(null);

	const handleClickOption = () => {
		optionRef.current?.click();
	};

	return (
		<>
			<div className="custom_option_answer_content mb-2" key={`question_create_${props.index}`}>
				<div className="custom_input_label">{`Option ${props.index + 1}`}</div>
				<div className="custom_input_field_container w-100 d-flex align-items-center">
					<div className="custom_input_field w-100 min_height_40 px-0 position-relative">
						<Radio.Group
							className="custom_radio_group_container"
							value={props.optionValue}
							onChange={(e) => props.handleRadioChange(props.index, e, e.target.checked)}
						>
							<Radio className="custom_radio_btn" value={props.index}>
								<textarea
									className="custom_input_text_area answer_option padding_right_65 shadow-none"
									disabled={props.disabled}
									placeholder="Enter your option"
									value={props.questionOptions.text}
									onChange={(e) => {
										props.setQuestion((prevState: any) => ({
											...prevState,
											options: props.setOptions(props.index, e),
										}));
										props.setInvalid((prevState: any) => ({
											...prevState,
											options: { ...prevState.options, [props.index]: false },
										}));
									}}
								/>
								<input
									accept=".png, .jpg, .jpeg, .svg .webp"
									type="file"
									ref={optionRef}
									style={{ display: "none" }}
									onChange={(e) => {
										props.handleOptionFileUpload(props.index, e.target.files);
									}}
								/>
							</Radio>
						</Radio.Group>
						<div className="custom_input_field_icon attach_delete_icon right_15">
							<span className="mr-1" onClick={handleClickOption}>
								<svg
									className="attach_svg cursor_pointer"
									width="31"
									height="31"
									viewBox="0 0 31 31"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M8.25789 31C7.90143 30.9398 7.5418 30.8967 7.18979 30.8175C3.80694 30.057 1.48965 28.0652 0.432351 24.7648C-0.456569 21.9917 0.0473008 19.3858 1.74063 17.0183C2.01703 16.6323 2.32837 16.2641 2.6664 15.9302C7.35118 11.299 12.0436 6.67472 16.7347 2.0492C18.8944 -0.0800892 21.8554 -0.587064 24.5869 0.702553C28.2316 2.42246 29.442 7.20831 27.0415 10.4358C26.7778 10.7907 26.4811 11.1272 26.1665 11.4384C21.5237 16.0265 16.8758 20.6089 12.2285 25.192C10.403 26.9924 7.68858 27.0684 5.86626 25.3738C4.14243 23.7705 4.02107 21.0329 5.59749 19.2876C5.69915 19.1754 5.80336 19.0652 5.91137 18.9587C10.0249 14.8991 14.1391 10.8401 18.2533 6.78118C18.3035 6.73175 18.3562 6.68549 18.3931 6.6519C18.9052 7.17282 19.411 7.68803 20.0007 8.28816C19.9638 8.30844 19.8355 8.34646 19.7516 8.42885C15.6761 12.4403 11.6071 16.4581 7.53163 20.4708C7.00171 20.993 6.67131 21.5855 6.73548 22.3504C6.80919 23.2249 7.26731 23.8466 8.05584 24.1958C8.82403 24.5354 9.57697 24.4416 10.2683 23.9562C10.4081 23.858 10.5352 23.7395 10.6571 23.6191C15.3063 19.0373 19.9517 14.4523 24.6035 9.87373C25.5667 8.92568 26.0878 7.80654 26.049 6.44214C25.988 4.29574 24.2337 2.43134 22.0898 2.23425C20.6526 2.1018 19.4129 2.52766 18.3874 3.53781C13.6804 8.17409 8.97017 12.8091 4.27459 17.4587C2.15364 19.5588 1.63897 22.4606 2.95615 25.1077C3.96643 27.1381 5.65213 28.3479 7.9008 28.6996C10.2098 29.0602 12.1878 28.3479 13.8424 26.7161C18.9503 21.6786 24.0577 16.6412 29.1637 11.6019C29.2641 11.503 29.3403 11.3807 29.4172 11.2831C29.9719 11.8478 30.4783 12.3636 31 12.8947C30.9733 12.9238 30.9085 12.998 30.8392 13.067C25.4657 18.3675 20.101 23.6761 14.704 28.9525C14.209 29.4366 13.5279 29.749 12.8988 30.0754C11.9127 30.5868 10.8503 30.8695 9.73837 30.9544C9.68944 30.9582 9.64242 30.9842 9.59413 31C9.14808 31 8.70267 31 8.25789 31Z"
										fill="#454849"
									/>
								</svg>
							</span>
							<span
								onClick={() => props.deleteClicked(props.index)}
								className={props.length <= 1 ? "disable_button" : "cursor_pointer"}
							>
								<svg
									className="attach_svg"
									width="21"
									height="26"
									viewBox="0 0 21 26"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M1.12717 14.6638C1.12717 12.5788 1.12576 10.4945 1.12788 8.40942C1.12859 7.74745 1.48612 7.29433 2.07588 7.19097C2.72794 7.07627 3.34035 7.55487 3.3885 8.21968C3.39558 8.31667 3.39204 8.41367 3.39204 8.51137C3.39204 12.5837 3.39204 16.6561 3.39204 20.7285C3.39204 22.0829 4.31455 23.004 5.67177 23.004C8.67507 23.004 11.6777 23.004 14.681 23.004C16.0375 23.004 16.9607 22.0815 16.9607 20.7278C16.9607 16.62 16.9607 12.5122 16.9614 8.40447C16.9614 7.76869 17.302 7.31345 17.8584 7.19946C18.4978 7.06778 19.0925 7.47345 19.2015 8.12056C19.222 8.24163 19.2263 8.36623 19.2263 8.48942C19.2277 12.5795 19.2284 16.6696 19.227 20.7596C19.2263 23.0011 17.7275 24.803 15.5249 25.203C15.2658 25.2504 14.9982 25.2667 14.7341 25.2674C11.6954 25.2717 8.65737 25.2724 5.61867 25.2695C3.09397 25.2674 1.13 23.3063 1.12646 20.7858C1.12505 18.7454 1.12717 16.705 1.12717 14.6638Z"
										fill="#E23813"
									/>
									<path
										d="M14.6958 3.7735C14.9918 3.7735 15.2467 3.7735 15.5022 3.7735C16.7207 3.7735 17.9391 3.76784 19.1576 3.77563C19.9838 3.78129 20.5276 4.48291 20.3031 5.23338C20.17 5.678 19.7792 5.9966 19.3169 6.03554C19.2553 6.04049 19.1937 6.03978 19.1314 6.03978C13.1623 6.03978 7.19325 6.04049 1.22346 6.03908C0.504138 6.03908 -0.0056172 5.55977 4.67316e-05 4.89709C0.00571067 4.24715 0.510509 3.77704 1.21992 3.77421C2.57997 3.76996 3.93932 3.77279 5.29937 3.77279C5.40415 3.77279 5.50964 3.77279 5.65761 3.77279C5.65761 3.66022 5.65761 3.56748 5.65761 3.47473C5.65761 2.83895 5.65336 2.20318 5.65903 1.5674C5.6654 0.856575 6.12559 0.384344 6.83713 0.38222C9.06235 0.375848 11.2876 0.375848 13.5128 0.38222C14.2257 0.384344 14.6873 0.853743 14.693 1.56457C14.7008 2.28813 14.6958 3.01029 14.6958 3.7735ZM7.92885 3.75085C9.43829 3.75085 10.9307 3.75085 12.4225 3.75085C12.4225 3.37207 12.4225 3.01595 12.4225 2.66195C10.9137 2.66195 9.42696 2.66195 7.92885 2.66195C7.92885 3.03365 7.92885 3.38977 7.92885 3.75085Z"
										fill="#E23813"
									/>
									<path
										d="M6.78622 14.4946C6.78622 13.1792 6.78127 11.8637 6.78905 10.5483C6.79189 9.99603 7.17987 9.55283 7.71936 9.44876C8.20929 9.35459 8.73674 9.61938 8.93569 10.0902C9.00932 10.2637 9.04613 10.4654 9.04684 10.6552C9.05392 13.233 9.05392 15.8115 9.05038 18.3893C9.04967 19.11 8.5647 19.6176 7.90273 19.6106C7.25279 19.6035 6.78764 19.0973 6.78622 18.3886C6.7841 17.0908 6.78551 15.7931 6.78622 14.4946Z"
										fill="#E23813"
									/>
									<path
										d="M13.57 14.5279C13.57 15.8434 13.5735 17.1588 13.5678 18.4743C13.5657 19.0088 13.2351 19.4286 12.7331 19.5674C12.2496 19.7012 11.72 19.5065 11.4821 19.0626C11.378 18.8686 11.3129 18.6286 11.3122 18.4091C11.3008 15.8136 11.3016 13.2174 11.3072 10.6219C11.3086 9.92314 11.8056 9.42401 12.4591 9.43392C13.0991 9.44313 13.5685 9.94651 13.57 10.6347C13.5721 11.9324 13.57 13.2302 13.57 14.5279Z"
										fill="#E23813"
									/>
								</svg>
							</span>
						</div>
						{props.invalid.options[props.index] ? (
							<span className="invalid_message invalid_top_minus_16">
								Please enter a valid option<option value=""></option>
							</span>
						) : (
							""
						)}
					</div>
					{props.length - 1 === props.index ? (
						<div className="custom_add_option">
							<span
								onClick={props.handleAddButtonClick}
								className={props.question.options.length >= 4 ? "disable_button pl-2" : "cursor_pointer pl-2"}
							>
								<svg
									className="attach_svg"
									width="31"
									height="30"
									viewBox="0 0 31 30"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M30.0555 14.1203C30.0555 14.7066 30.0555 15.2922 30.0555 15.8785C30.0313 16.0891 30.0071 16.2996 29.9836 16.511C29.6241 19.7389 28.3884 22.5859 26.2091 25.0008C23.8955 27.5646 21.0279 29.1642 17.6165 29.7667C17.0508 29.8665 16.4784 29.923 15.9083 30C15.3213 30 14.7342 30 14.1472 30C14.0716 29.9831 13.9968 29.9574 13.9205 29.9516C11.5695 29.7857 9.37033 29.1151 7.36419 27.8875C3.4715 25.5056 1.07425 22.073 0.234075 17.5801C0.129145 17.018 0.0770466 16.4457 0 15.8785C0 15.2922 0 14.7066 0 14.1203C0.0242146 13.9097 0.0484293 13.6984 0.0719101 13.4878C0.427058 10.312 1.62165 7.49579 3.754 5.10661C6.58492 1.93376 10.1467 0.224788 14.3974 0.0185969C17.3612 -0.125223 20.1481 0.564527 22.6855 2.10913C26.5855 4.48363 28.9783 7.92431 29.8214 12.4172C29.9271 12.98 29.9785 13.5531 30.0555 14.1203ZM27.7214 15.0287C27.7595 8.07107 22.0838 2.37622 15.0644 2.32926C8.10017 2.2823 2.39065 7.94633 2.33414 14.9554C2.27838 21.9138 7.98643 27.6409 15.0057 27.6695C21.984 27.6981 27.6832 22.0326 27.7214 15.0287Z"
										fill="#484549"
									/>
									<path
										d="M16.1969 13.7276C16.3444 13.7276 16.4596 13.7276 16.5741 13.7276C17.9529 13.7276 19.3324 13.7246 20.7111 13.729C21.3216 13.7312 21.7406 13.9932 21.9285 14.4709C22.2315 15.2428 21.6783 16.0463 20.8205 16.0522C19.4021 16.0617 17.9844 16.0551 16.566 16.0551C16.4589 16.0551 16.3525 16.0551 16.1977 16.0551C16.1977 16.1696 16.1977 16.2716 16.1977 16.3736C16.1977 17.789 16.2021 19.2045 16.1955 20.6199C16.1918 21.3368 15.7156 21.8461 15.0596 21.8586C14.3706 21.8718 13.8657 21.3618 13.8621 20.6229C13.8547 19.217 13.8599 17.8118 13.8599 16.4059C13.8599 16.3002 13.8599 16.1953 13.8599 16.0551C13.7314 16.0551 13.6272 16.0551 13.5231 16.0551C12.0951 16.0551 10.6672 16.061 9.23926 16.0522C8.40422 16.047 7.84875 15.292 8.10998 14.5296C8.25747 14.0989 8.56565 13.8325 9.02206 13.7584C9.16588 13.7349 9.31411 13.729 9.46013 13.729C10.8 13.7268 12.1399 13.7276 13.4798 13.7276C13.595 13.7276 13.7102 13.7276 13.8599 13.7276C13.8599 13.5955 13.8599 13.4913 13.8599 13.3871C13.8599 11.9915 13.8577 10.5951 13.8613 9.19943C13.8628 8.57645 14.1857 8.11784 14.7088 7.97329C15.4859 7.75829 16.1896 8.31523 16.1947 9.16788C16.2028 10.5738 16.1969 11.979 16.1969 13.3849C16.1969 13.4891 16.1969 13.594 16.1969 13.7276Z"
										fill="#484549"
									/>
								</svg>
							</span>
						</div>
					) : (
						<div className="custom_add_option"></div>
					)}
				</div>

				{props.invalid.optionLength ? (
					<span className="invalid_message invalid_top_minus_16">Please select at least two options to proceed</span>
				) : (
					""
				)}
				{props.questionOptions.attachmentName ? (
					<div className="attachment_img_box">
						<div className="attachment_img_container d-flex align-items-center justify-content-between">
							{props.questionOptions.attachmentName}
							{props.questionOptions.attachmentName ? (
								<div
									className="clear_icon_svg cursor_pointer ml-2"
									onClick={() => props.crossButtonOptionClick(props.index)}
								>
									<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path
											d="M0 0.546663C0.0175343 0.50575 0.0350687 0.464838 0.0522377 0.42356C0.223197 0.0107797 0.732424 -0.130954 1.08969 0.136075C1.14777 0.179545 1.19927 0.232147 1.25078 0.283287C3.12622 2.15797 5.0013 4.03264 6.87565 5.90842C6.91364 5.94641 6.94469 5.99134 6.98524 6.04065C7.04369 5.98476 7.08314 5.94897 7.12076 5.91098C9.00936 4.02351 10.8976 2.13605 12.7844 0.247124C12.9827 0.0484048 13.2081 -0.0483976 13.4883 0.0242955C13.9602 0.146303 14.1542 0.700816 13.862 1.09241C13.8185 1.15049 13.7659 1.20163 13.7144 1.25314C11.843 3.12526 9.97119 4.99701 8.09903 6.86841C8.06031 6.90676 8.01611 6.93964 7.96095 6.98676C8.0099 7.03827 8.04497 7.07662 8.08186 7.11352C9.96644 8.99842 11.8503 10.8837 13.7367 12.7668C13.9624 12.9922 14.0651 13.2435 13.9537 13.5573C13.8686 13.7965 13.6782 13.9178 13.4536 14C13.3623 14 13.2713 14 13.18 14C12.9743 13.9339 12.8238 13.7932 12.6748 13.6438C10.8238 11.7896 8.97064 9.93759 7.11784 8.08483C7.08022 8.0472 7.04113 8.01177 6.98232 7.95551C6.94031 8.00921 6.91072 8.05597 6.87236 8.09433C5.02359 9.94453 3.17335 11.7933 1.32567 13.6442C1.17663 13.7936 1.02612 13.9342 0.82046 14C0.729136 14 0.638177 14 0.546852 14C0.272513 13.9098 0.086941 13.7308 0 13.4532C0 13.3618 0 13.2709 0 13.1796C0.0668496 12.9743 0.206759 12.8234 0.356166 12.6744C2.21042 10.8234 4.06285 8.9703 5.91528 7.11753C5.9529 7.07991 5.9887 7.04082 6.04533 6.98165C5.9887 6.93635 5.93829 6.90384 5.89665 6.8622C4.04641 5.01345 2.19764 3.16325 0.346303 1.3156C0.200184 1.16985 0.0642925 1.02118 0 0.820267C0 0.728944 0 0.637986 0 0.546663Z"
											fill="#A3A2A4"
										/>
									</svg>
								</div>
							) : (
								""
							)}
						</div>
					</div>
				) : (
					""
				)}
			</div>
		</>
	);
};
