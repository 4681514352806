import React from "react";
import "./header.scss";
import { Locations } from "../../constants/locations";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../modal/hooks";

export const Header: React.FunctionComponent = () => {
	const { pathname } = useLocation();
	const resultCount = useAppSelector((store) => store.results.totalCount);
	const selectedCount = useAppSelector((store) => store.results.selectedCount);

	const getHeader = (): string => {
		if (pathname === Locations.QUESTION) {
			return "Questions";
		} else if (pathname === Locations.RESULT) {
			return "Results";
		} else if (pathname === Locations.COLLEGE) {
			return "College";
		} else if (pathname.includes(Locations.EVALUATION_BREAKDOWN.replace(":id", ""))) {
			return "Evaluation Breakdown";
		}else {
			return "";
		}
	};
	return (
		<div className="custom_header">
			<div className="header_logo_container">
				<div className="main_logo_container">
					<img className="logo_img" src="/images/logo.png" />
				</div>
				<div className="logo_sub_header">
					<span>Talent </span>
					<span className="pl_2">Quest </span>
				</div>
			</div>
			<div className="d-flex align-items-center justify-content-between w-100">
				<div className="custom_main_heading font_26">{getHeader()}</div>
				{pathname === Locations.RESULT ? (
					<div className="select_count_details font_medium">
						<div className="text-right mb-1">
							<span className="count_label">Total attendees: </span>
							<span className="count_number">{resultCount}</span>
						</div>
						<div className="text-right">
							<span className="count_label">Selected: </span>
							<span className="count_number">{selectedCount}</span>
						</div>
					</div>
				) : (
					""
				)}
			</div>
		</div>
	);
};
